/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _JSXStyle from "styled-jsx/style";
import { Layout } from "antd";
import Cookies from "js-cookie";
import AppContext from "../app-context";
// import HeaderComponent from './Header/header-new';
import HeaderComponentLP from "./Header/header-lp";
import Header from "../../components-v2/Header";
import Footer from "./Footer/footer";
import FooterLP from "./Footer/footer-lp";
import GlobalFormStyles from "./GlobalFormStyles";
import GlobalStyles from "./GlobalStyles";
import CustomBanner from "../CustomBanner";
import { footerData } from "../../data/footer-data";
import { getUrlParams } from "../../../helpers";
import { NewAquisition } from "../../data/Misc/BannerData";
import FooterStyles from "./Footer/FooterStyles";
import SecondaryHeader from "./Header/secondary-header";
import HeaderLP from "../../components-v2/HeaderLP";
import HeaderCallback from "../../components-v2/HeaderCallback";
import "../../consent-banner.css";

const { Content } = Layout;

if (typeof global !== "undefined") {
  Object.assign(global, { _JSXStyle });
}

const LayoutComponent = (props) => {
  const {
    children,
    className,
    isLanding,
    phoneText,
    phoneNumber,
    transparentHeader,
    hideBanner,
    relMenuHeight,
    noTopOffset,
    secondaryNav,
    useNewLPHeader,
    useDarkLogo,
    getADemo,
    noFooter,
    specialHeader,
    customBannerData,
  } = props;

  const [navVisible, setNavVisible] = useState(false);
  const [isDemoUrl, setIsDemoUrl] = useState(false);
  const [subItemsVisible, setSubItemsVisible] = useState(false);
  const toggleNavVisible = () => {
    if (navVisible) {
      setSubItemsVisible(false);
      setNavVisible(false);
      return;
    }

    setNavVisible(true);
  };
  const toggleSubItemsVisible = () => setSubItemsVisible(!subItemsVisible);

  useEffect(() => {
    let checkPath;
    if (typeof window !== `undefined`) {
      const storage = window.sessionStorage;
      if (
        window?.location?.search?.length &&
        !storage.getItem("searchParams")
      ) {
        // eslint-disable-next-line no-unused-expressions
        if (getUrlParams(window.location.search).your_name) {
          storage.setItem(
            "referrerName",
            getUrlParams(window.location.search).your_name
          );
          storage.setItem(
            "referrerCompany",
            getUrlParams(window.location.search).company
          );
          storage.setItem(
            "referrerEmail",
            getUrlParams(window.location.search).email
          );
          storage.setItem(
            "referrerMerchantId",
            getUrlParams(window.location.search).your_merchant_id
          );
          window.location.search = "";
        } else {
          storage.setItem("searchParams", window.location.search);
        }
      }
      if (storage.getItem("searchParams") && !window.location.search.length) {
        if (storage.getItem("searchParams"))
          window.location.search = storage.getItem("searchParams");
      }
      checkPath = window.location.pathname.split("/");
      if (checkPath[checkPath.length - 1] === "demo") setIsDemoUrl(true);
      if (hideBanner) setIsDemoUrl(true);
    }
  });

  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];

    return isDemoUrl
      ? bodyElement.classList.add("demo-page")
      : bodyElement.classList.remove("demo-page");
  }, [isDemoUrl]);

  if (typeof window !== `undefined`) {
    const utmSource = getUrlParams(window.location.search).utm_source;
    const utmMedium = getUrlParams(window.location.search).utm_medium;
    const utmCampaign = getUrlParams(window.location.search).utm_campaign;
    const utmContent = getUrlParams(window.location.search).utm_content;
    const utmTerm = getUrlParams(window.location.search).utm_term;
    const { gclid } = getUrlParams(window.location.search);
    const { fbclid } = getUrlParams(window.location.search);
    const { irclickid } = getUrlParams(window.location.search);
    const { irpid } = getUrlParams(window.location.search);
    const { prsd } = getUrlParams(window.location.search);
    const allyId = getUrlParams(window.location.search).ally_id;
    if (allyId && utmCampaign) {
      Cookies.set("utmCampaign", utmCampaign, { expires: 90 });
    }
    if (prsd) {
      Cookies.set("prsd", prsd, { expires: 90 });
    }
    if (utmSource) {
      Cookies.set("utmSource", utmSource, { expires: 90 });
      if (utmMedium) Cookies.set("utmMedium", utmMedium, { expires: 90 });
      if (utmCampaign) Cookies.set("utmCampaign", utmCampaign, { expires: 90 });
      if (utmContent) Cookies.set("utmContent", utmContent, { expires: 90 });
      if (utmTerm) Cookies.set("utmTerm", utmTerm, { expires: 90 });
    }
    if (gclid) {
      Cookies.set("gclid", gclid, { expires: 90 });
      Cookies.set("utmSource", "google", { expires: 90 });
      Cookies.set("utmMedium", "cpc", { expires: 90 });
      Cookies.set("utmCampaign", utmCampaign || "not_specified", {
        expires: 90,
      });
      Cookies.set("utmContent", utmContent || "not_specified", { expires: 90 });
    }
    if (fbclid && !utmMedium) {
      Cookies.set("utmSource", "facebook", { expires: 90 });
      Cookies.set("utmMedium", "referral", { expires: 90 });
    }
    if (irclickid) {
      Cookies.set("irclickid", irclickid, { expires: 90 });
      Cookies.set("utmSource", "affiliate-marketing", { expires: 90 });
      Cookies.set("utmMedium", "paid", { expires: 90 });
      Cookies.set("utmTerm", irclickid, { expires: 90 });
      Cookies.set("irpid", irpid, { expires: 90 });
      Cookies.set("utmCampaign", irpid, { expires: 90 });
    }
  }

  const context = {
    navVisible,
    setNavVisible,
    toggleNavVisible,
    subItemsVisible,
    setSubItemsVisible,
    toggleSubItemsVisible,
  };

  const contentStyles = {
    overflow: navVisible ? "hidden" : "auto",
  };

  return (
    <Layout className={className || ""}>
      <AppContext.Provider value={context}>
        {/* <CustomBanner
          customData={customBannerData || NewAquisition}
          hideBanner={
            isDemoUrl ? true : !!isLanding || !!secondaryNav || !!hideBanner
          }
        /> */}
        <HeaderLP
          className={className}
          transparentHeader={transparentHeader}
          phoneNumber={phoneNumber}
          useDarkLogo={useDarkLogo}
          phoneText={phoneText}
        />

        {/* {isLanding && !secondaryNav && useNewLPHeader && (
          <HeaderLP
            className={className}
            transparentHeader={transparentHeader}
            phoneNumber={phoneNumber}
            useDarkLogo={useDarkLogo}
            phoneText={phoneText}
          />
        )} */}
        {/* {!isLanding && !secondaryNav && specialHeader && (
          <HeaderCallback
            className={className}
            transparentHeader={transparentHeader}
          />
        )} */}

        {/* {isLanding && !secondaryNav && !useNewLPHeader && !specialHeader && (
          <HeaderComponentLP
            className={className}
            transparentHeader={transparentHeader}
            phoneNumber={phoneNumber}
            phoneText={phoneText}
            noTopOffset={noTopOffset}
            getADemo={getADemo}
          />
        )} */}
        {/* {secondaryNav && (
          <SecondaryHeader
            relMenuHeight={relMenuHeight}
            phoneNumber={phoneNumber}
            phoneText={phoneText}
          />
        )} */}
        {/* {!isLanding && !secondaryNav && !specialHeader && (
          <Header
            className={className}
            transparentHeader={transparentHeader}
            hideBanner={hideBanner}
            relMenuHeight={relMenuHeight}
            phoneNumber={phoneNumber.length ? phoneNumber : null}
            phoneText={phoneText.length ? phoneText : null}
            noTopOffset={isDemoUrl ? "0" : noTopOffset}
          />
        )} */}

        <Content
          className={`content-wrapper ${isLanding && "is-lp"} `}
          style={contentStyles}
        >
          {children}
        </Content>
        <FooterLP />
        {/* {isLanding && <FooterLP />}
        {!isLanding && !noFooter && (
          <Footer
            sectionData={footerData}
            phoneNumber={phoneNumber.length ? phoneNumber : null}
            phoneText={phoneText.length ? phoneText : null}
          />
        )} */}
      </AppContext.Provider>

      <GlobalStyles />
      <GlobalFormStyles />
      <FooterStyles />
      {/* eslint-disable-next-line react/style-prop-object */}
    </Layout>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLanding: PropTypes.bool,
  secondaryNav: PropTypes.bool,
  phoneNumber: PropTypes.string,
  phoneText: PropTypes.string,
  transparentHeader: PropTypes.bool,
  hideBanner: PropTypes.bool,
  relMenuHeight: PropTypes.number,
  noTopOffset: PropTypes.bool,
  useNewLPHeader: PropTypes.bool,
  useDarkLogo: PropTypes.bool,
  getADemo: PropTypes.bool,
  noFooter: PropTypes.bool,
  specialHeader: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customBannerData: PropTypes.object,
};
LayoutComponent.defaultProps = {
  className: "",
  isLanding: false,
  secondaryNav: false,
  phoneNumber: "",
  phoneText: "",
  transparentHeader: false,
  hideBanner: false,
  relMenuHeight: 65,
  noTopOffset: false,
  useNewLPHeader: false,
  useDarkLogo: false,
  getADemo: false,
  noFooter: false,
  specialHeader: false,
  customBannerData: null,
};

export default LayoutComponent;
