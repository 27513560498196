import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Layout, Col, Drawer } from "antd";
import { bool, string } from "prop-types";
import Burger from "react-css-burger";
import Logo from "../../../images/svg/SpotOnLogo.svg";
import LogoWhite from "../../../images/svg/SpotOnLogoWhite.svg";
import HeaderStyles from "./HeaderStyles";
import phone from "../../../images/svg/phone.svg";
import useWindowSize from "../../../hooks/use-window-size";
import useGetViewportY from "../../../hooks/use-get-viewport-y";
import useShouldHideDemoCta from "../../../hooks/use-should-hide-demo-cta";
import CtaPrimary from "../../Buttons/primary-cta";

const { Header } = Layout;

// eslint-disable-next-line react/prop-types
const SecondaryHeader = (
  { phoneNumber, phoneText, transparentHeader },
  noTopOffset
) => {
  const hideDemoCta = useShouldHideDemoCta();
  const [shouldBeTransparent, setShouldBeTransparent] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagePath, setPagePAth] = useState("");
  const viewportY = useGetViewportY();
  const size = useWindowSize();
  const LgOrSmaller = size.width < 1200;
  // const { navVisible } = useContext(AppContext);
  const onClose = () => {
    setVisible(false);
  };
  const toggleNav = () => {
    if (visible) {
      return setVisible(false);
    }

    return setVisible(true);
  };
  useEffect(() => {
    setShouldBeTransparent(Math.abs(viewportY) <= 100 && transparentHeader);
  }, [viewportY]);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setPagePAth(window.location.pathname);
    }
  }, []);

  return (
    <Header
      className={`nav is-lp ${shouldBeTransparent ? "transparent" : ""}`}
      style={{
        /* eslint-disable-next-line no-nested-ternary */
        top: noTopOffset ? 0 : LgOrSmaller ? 90 : 60,
      }}
    >
      <Col
        xs={{ span: 6 }}
        sm={{ span: 6 }}
        md={{ span: 6 }}
        lg={{ span: 0 }}
        xl={{ span: 0 }}
        xxl={{ span: 0 }}
      >
        {typeof document !== `undefined` && (
          <Burger
            active={visible}
            onClick={toggleNav}
            burger="spring"
            color={transparentHeader && !visible ? "#fff" : "#000"}
          />
        )}

        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
          key="left"
        >
          <ul className="sec-menu">
            <li>
              <AnchorLink to={`${pagePath}#pos`}>Point of Sale</AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#cust`}>
                Customer Testimonials
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#oo`}>Online Ordering</AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#rct`}>
                Reservations &amp; Waitlists
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#cp`}>Contactless Payment</AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#service`}>
                Service &amp; Support
              </AnchorLink>
            </li>
            <li>
              {!hideDemoCta && (
                <CtaPrimary ctaTitle="Learn more" target="/demo" />
              )}
            </li>
          </ul>
        </Drawer>
      </Col>

      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8, offset: 2 }}
        lg={{ span: 4, offset: 1 }}
        xl={{ span: 4, offset: 1 }}
        xxl={{ span: 4, offset: 1 }}
      >
        <Link to="/" style={{ margin: "auto" }}>
          <img
            className="header__logo"
            src={shouldBeTransparent ? LogoWhite : Logo}
            alt="logo"
          />
        </Link>
      </Col>
      <Col
        xs={{ span: 0 }}
        sm={{ span: 0 }}
        md={{ span: 0 }}
        lg={{ span: 14 }}
        xl={{ span: 14 }}
        xxl={{ span: 14 }}
      >
        <div className="table-hidden">
          <ul className="specialty-menu">
            <li>
              <AnchorLink to={`${pagePath}#pos`}>Point of Sale</AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#cust`}>
                Customer Testimonials
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#oo`}>Online Ordering</AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#rct`}>
                Reservations &amp; Waitlists
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#cp`}>Contactless Payment</AnchorLink>
            </li>
            <li>
              <AnchorLink to={`${pagePath}#service`}>
                Service &amp; Support
              </AnchorLink>
            </li>
          </ul>
        </div>
      </Col>

      <Col xs={6} sm={6} md={6} lg={4} pull={1} className="phone">
        <a href={`tel:${phoneNumber}`}>
          <img src={phone} alt="phone icon" />
          <span>{phoneText}</span>
        </a>
      </Col>
      <HeaderStyles />
    </Header>
  );
};

SecondaryHeader.propTypes = {
  transparentHeader: bool,
  phoneNumber: string,
  phoneText: string,
};

SecondaryHeader.defaultProps = {
  transparentHeader: false,
  phoneNumber: "",
  phoneText: "",
};

export default SecondaryHeader;
