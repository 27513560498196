import React from "react";
import { colors, fontFamily, viewports } from "../../style-vars";
import largeCtaBg from "../../images/svg/large-cta-bg.svg";
import smallCtaBg from "../../images/svg/small-cta-bg.svg";

const {
  xsOnly,
  smOnly,
  smOrSmaller,
  mdOrSmaller,
  lgOrBigger,
  mdOrBigger,
  lgOnly,
} = viewports;
const { white, black, textGray, primary, paragraph } = colors;

const GlobalStyles = () => (
  <style jsx global>{`
    html {
      //scroll-behavior: smooth;
    }
    body {
      scroll-behavior: smooth;
    }
    .new-line {
      white-space: pre-line;
    }
    .ctaNew {
      width: 100% !important;
    }
    body.demo-page header.header {
      top: 0 !important;
    }
    .callback-height {
      min-height: calc(100vh - 72px);
    }
    .callback-header {
      box-shadow: 0px 3.35045px 17.869px rgba(24, 36, 65, 0.0417275),
        0px 1.87823px 10.0172px rgba(24, 36, 65, 0.035);
    }

    .custom-img {
      width: 75%;
      justify-self: center;
      align-self: center;
    }

    //demo.less
    .demo {
      max-width: 1440px !important;
      position: relative;
      z-index: 1;
      font-family: ${fontFamily} !important;
    }
    @media ${lgOrBigger} {
      .demo {
        margin-top: 80px;
      }
    }
    .demo__content-wrapper {
      position: relative;
      z-index: 3;
      max-width: 1440px;
      padding-bottom: 60px;
      margin-top: 20px;
      display: flex;
      flex-direction: column-reverse;
    }
    .force-original {
      top: 0 !important;
      position: relative !important;
    }
    @media ${lgOrBigger} {
      .demo__content-wrapper {
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 0px;
        margin-top: 0px;
      }
    }
    .demoLP__content-wrapper {
      position: relative;
      z-index: 3;
      max-width: 1440px;
      padding-bottom: 60px;
      margin-top: 20px;
      display: flex;
      align-items: center;
    }
    @media ${lgOrBigger} {
      .demoLP__content-wrapper {
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 0px;
        margin-top: 0px;
      }
    }
    .form-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    @media ${lgOrBigger} {
      .form-wrapper {
        width: 50%;
        margin: 30px 70px 30px 70px;
      }
    }
    @media ${lgOrBigger} {
      .form-wrapper.is-landing {
        width: 50%;
        margin: 20px 0px 0px 0px;
      }
    }
    .images {
      display: flex;
      justify-content: center;
    }
    .image-background {
      height: 15%;
      position: absolute;
      top: 77px;
    }
    @media ${lgOrBigger} {
      .image-background {
        height: 70%;
        right: -10%;
        top: -120px;
      }
    }
    @media screen and (min-width: 1300px) {
      .image-background {
        height: 95%;
        right: -8%;
        top: -12%;
      }
    }
    .demo__heading-img {
      width: 50%;
      position: absolute !important;
      top: 0;
      left: 0;
    }
    .demo__heading-img.auto {
      top: 9%;
    }
    @media ${mdOrSmaller} {
      .demo__heading-img {
        display: none;
      }
    }
    .demoLP__heading-img {
      width: 50%;
    }
    @media ${mdOrSmaller} {
      .demoLP__heading-img {
        display: none;
      }
    }
    .demo__heading-img .stories {
      max-width: 100%;
      margin: auto;
      margin-bottom: 60px !important;
      margin-top: 40px;
    }
    @media screen and (min-width: 1300px) {
      .demo__heading-img .stories {
        max-width: 1440px;
      }
    }
    .demo__heading-img .section-wrapper {
      margin: 20px 0px;
    }
    @media ${lgOrBigger} {
      .demo__heading-img .section-wrapper {
        margin: 0px 70px;
      }
    }
    .badges {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
      flex-wrap: wrap;
    }
    .badges a {
      color: black !important;
      font-size: 18px !important;
      text-align: center;
      cursor: pointer;
    }
    @media ${smOrSmaller} {
      .badges a {
        padding: 0px 15px;
      }
    }
    .capterra-badge {
      display: flex;
      flex-direction: column;
      width: 15%;
      justify-content: space-between;
      align-items: center;
    }
    @media ${smOrSmaller} {
      .capterra-badge {
        width: 50%;
      }
    }
    .capterra-badge.reviews-capterra {
      margin-top: 2em;
    }
    @media ${mdOrSmaller} {
      .capterra-badge.reviews-capterra {
        margin-top: 1em;
      }
    }
    .capterra-badge img {
      width: 80%;
      margin-bottom: 20px;
    }

    // cta.less
    .cta {
      position: relative;
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
    .cta-large-banner {
      position: relative;
      margin-top: 40px !important;
      margin-bottom: 40px !important;
      margin: 40px 100px;
    }

    @media ${mdOrSmaller} {
      .cta {
        margin: auto;
        max-width: 95%;
      }
      .cta-large-banner {
        position: relative;
        margin-top: 40px !important;
        margin-bottom: 40px !important;
        margin: 0;
        margin-left: 16px;
        margin-right: 16px;
        display: table;
        max-width: 95%;
        width: 95%;
      }
    }
    .cta h1 {
      color: ${white} !important;
      font-size: 46px !important;
      margin-top: 32px;
    }
    @media ${mdOrSmaller} {
      .cta h1 {
        font-size: 32px !important;
      }
    }
    .cta p {
      color: ${white};
      font-size: 20px;
      line-height: 2;
    }
    .cta .cta-primary {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .cta-img {
      width: 100%;
    }
    .cta-large {
      background-color: #092a66;
      background-image: url(${largeCtaBg});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top 0% left 0%;
      border-radius: 28px;
      // padding: 0 72px;
      max-width: 100%;
      margin: 0 auto;
    }
    .cta-large h1.ant-typography {
      color: ${white} !important;
    }
    @media ${mdOrSmaller} {
      .cta-large {
        padding: 0;
        max-width: 100%;
        background-image: none;
      }
    }
    .cta-small {
      background-color: #092a66;
      background-image: url(${smallCtaBg});
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 28px;
      padding: 50px 72px;
      max-width: 75%;
      margin: 0 auto;
    }
    @media ${mdOrSmaller} {
      .cta-small {
        max-width: 100%;
      }
    }
    .cta-small h2.ant-typography {
      font-size: 32px;
      font-weight: 800 !important;
      color: ${white} !important;
    }
    .cta-small .false-center {
      display: table;
      margin: 0 auto !important;
    }
    section.cta {
    }

    // global.less
    body {
      overflow-x: hidden;
      min-height: 100vh;
      //scroll-behavior: smooth;
      text-rendering: optimizeLegibility;
      transform: translate3d(0px);
    }
    main {
      overflow: hidden !important;
      //scroll-behavior: smooth;
    }
    .content-wrapper {
      margin-top: 60px;
      max-width: 1440px;
    }
    .content-wrapper.is-lp {
      margin-top: 0px;
      max-width: 1440px;
    }
    section.ant-layout {
      max-width: 1440px !important;
      margin: 0 auto;
      background: ${white} !important;
    }
    .ant-layout.non-fixed-width {
      max-width: 100% !important;
    }
    .ant-layout.non-fixed-width .content-wrapper {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-layout.non-fixed-width .ant-layout-footer {
      margin: 0 auto;
    }
    .ant-layout.full-size {
      max-width: 100vw;
    }

    button.ant-btn.ant-btn-secondary {
      border: none !important;
    }
    button.ant-btn.ant-btn-primary:hover,
    button.ant-btn.ant-btn-primary:focus {
      opacity: 0.85;
    }
    .ant-input {
      border-radius: 0 !important;
    }
    .ant-menu-horizontal {
      border-bottom: none !important;
    }
    h1.ant-typography {
      font-weight: bold !important;
      font-size: 38px !important;
      line-height: 1.4 !important;
      color: ${black} !important;
      letter-spacing: -0.02em;
      font-family: ${fontFamily} !important;
    }
    h2.ant-typography {
      margin-bottom: 0.5em !important;
      color: ${black} !important;
      font-weight: bold !important;
      font-size: 32px !important;
      line-height: 110% !important;
      letter-spacing: -0.03em;
      font-family: ${fontFamily};
    }
    h3.ant-typography {
      font-weight: 700 !important;
      line-height: 22px !important;
      color: ${textGray};
    }
    h4.ant-typography {
      font-weight: 700 !important;
      line-height: 19px !important;
      color: ${textGray} !important;
    }
    .noselect {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .ant-select-selector {
      height: 40px !important;
      padding: 8px 11px !important;
      align-items: center !important;
    }
    .slider:focus {
      outline: none !important;
    }
    .cta-primary {
      background-color: ${primary} !important;
      display: flex !important;
      align-items: center;
      align-content: center;
      justify-content: center;
      height: 42px !important;
      border-radius: 100px !important;
      margin-right: 30px !important;
      font-size: 18px !important;
      padding: 10px 24px !important;
    }
    @media ${mdOrSmaller} {
      .cta-primary {
        margin-right: 0px;
      }
    }
    .cta-primary span {
      line-height: 1;
    }
    .cta-primary.play-video {
      padding: 10px !important;
      padding-left: 20px !important;
    }
    .cta-secondary {
      padding-left: 0 !important;
      background-color: transparent !important;
      border: none !important;
      position: relative !important;
      box-shadow: none !important;
      padding: 0;
      border-radius: 0;
      font-size: 18px !important;
      transform: translateX(0);
    }
    .cta-secondary span {
      position: relative !important;
      font-weight: 800 !important;
    }
    .cta-secondary span:before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: rgba(23, 105, 255, 0.1);
      position: absolute;
      bottom: -4px;
      left: -2px;
    }
    .cta-secondary span:after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: rgba(23, 105, 255, 0.5);
      position: absolute;
      bottom: -4px;
      left: -2px;
      transition: transform 0.3s ease;
      will-change: auto;
      transform: scaleX(0);
    }
    .cta-secondary.white-border span:after {
      background-color: ${white};
    }
    .cta-secondary:hover {
      box-shadow: none !important;
    }
    .cta-secondary:hover span:after {
      background-color: rgba(23, 105, 255, 0.5);
      will-change: auto;
      transform: scaleX(1);
    }
    .cta-secondary.white-border:hover span:after {
      background-color: ${white};
    }
    .main-heading {
      font-weight: 800 !important;
      line-height: 1.8 !important;
      margin-top: 40px;
    }
    .normal-paragraph {
      font-family: ${fontFamily} !important;
      font-size: 20px !important;
      font-weight: 400 !important;
      line-height: 1.8 !important;
      color: ${paragraph} !important;
    }
    .icon-custom {
      background-color: ${primary};
      border-radius: 6px;
      padding: 10px 15px;
      margin: 10px 24px 10px 0px !important;
    }
    .blue-dot {
      font-family: ${fontFamily};
      position: relative !important;
    }
    .blue-dot:after {
      content: ".";
      position: absolute;
      color: ${primary} !important;
      transform: translateX(-8.5px) translateY(-1px) scale(1.2);
    }
    .blue-sign {
      color: ${primary} !important;
      font-family: ${fontFamily};
      font-size: 42px;
      margin-left: 5px;
    }
    .eye-not-visible {
      width: 0 !important;
      height: 0 !important;
      color: transparent;
      font-size: 0px;
      position: absolute;
      left: -100px;
    }
    .btn-right {
      width: 42px;
      height: 42px;
      border-radius: 84px;
      background: rgba(23, 105, 255, 0.1);
      border: none;
      cursor: pointer;
      display: inline-block;
      margin-left: 100px;
    }
    .btn-left {
      width: 42px;
      height: 42px;
      border-radius: 84px;
      background: rgba(23, 105, 255, 0.1);
      border: none;
      cursor: pointer;
      display: inline-block;
    }
    //.text-center {
    //  text-align: center;
    //}
    .text-center.small-width {
      max-width: 70%;
      margin: auto;
    }
    @media ${mdOrSmaller} {
      .text-center.small-width {
        max-width: 95%;
      }
    }
    @media ${smOrSmaller} {
      .text-center.small-width {
        max-width: 100%;
      }
    }
    .blue_dot {
      color: ${primary} !important;
      margin: 0;
    }
    .small-grid {
      display: grid;
      grid-template-columns: 64px 1fr;
      grid-gap: 16px;
      padding: 0 160px;
      margin-bottom: 64px;
    }
    @media ${smOrSmaller} {
      .small-grid {
        padding: 0 32px;
      }
    }
    .small-grid h4 {
      font-weight: 800;
      font-size: 16px;
    }
    .small-grid-icon {
      width: 64px;
    }
    @media ${mdOrSmaller} {
      .arrow-right,
      .arrow-left {
        width: 40px;
      }
    }
    .arrow-right:hover,
    .arrow-left:hover {
      cursor: pointer;
    }
    .gbi::after {
      border-radius: 24px !important;
    }
    .gradient-cover {
      background: linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 67.28%,
        rgba(0, 0, 0, 0.8) 100%
      );
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .gradient-cover.is-radial {
      background: ${black}000 !important;
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(159, 153, 153, 0) 100%
      ) !important;
    }
    .drop-down__small {
      height: 40px !important;
      padding: 0 11px !important;
    }

    @media ${smOrSmaller} {
      .about-us section.cta .cta-small {
        padding: 50px 40px;
      }
      .about-us section.cta .cta-small h2 {
        padding: 0 20px;
      }

      .demo .trusted-numbers-wrapper {
        margin: 40px auto;
      }
    }

    @media ${mdOrBigger} {
      body.with-banner .hero-b {
        margin-top: 120px;
      }
      body.with-banner .hero {
        margin-top: 60px;
      }
      .demo .trusted-numbers-wrapper {
        margin: 120px auto;
      }
    }

    .why-spoton .logos-wrapper {
      margin-bottom: 60px;
    }

    @media ${lgOnly} {
      div.override_ant_col {
        flex: auto;
        max-width: max-content;
      }
    }

    .extra-large-heading {
      font-size: 42px;
      line-height: 46px;
      font-weight: 700;
      color: #090d12;
    }
    @media ${lgOrBigger} {
      .extra-large-heading {
        font-size: 72px;
        line-height: 80px;
        font-weight: 800;
      }
    }

    .ant-modal {
      width: 90vw !important;
    }
    .ant-modal-content {
      border-radius: 32px;
      overflow: hidden;
      height: 80vh !important;
      overflow: scroll !important;
    }
    div.ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal.video-modal {
      width: 40vw !important;
    }
    .ant-modal.video-modal .ant-modal-content {
      max-height: 70vh !important;
      height: auto !important;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .ant-modal.video-modal iframe {
      margin: 0 auto;
      max-width: 570px !important;
      max-height: 350px !important;
      width: 100% !important;
    }

    .ant-modal.video-modal .video-wrapper {
      height: 350px;
    }

    .ant-modal.video-modal .ant-modal-content::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 768px) and (min-width: 576px) {
      .ant-modal.video-modal {
        width: 80vw !important;
      }
      .ant-modal.video-modal iframe {
        max-width: 570px !important;
        max-height: 300px !important;
        width: 100% !important;
      }

      .ant-modal.video-modal .video-wrapper {
        height: 300px !important;
      }
    }

    @media ${xsOnly} {
      .ant-modal.video-modal {
        width: 95vw !important;
      }
      .ant-modal.video-modal iframe {
        max-width: 570px !important;
        max-height: 300px !important;
        width: 100% !important;
      }

      .ant-modal.video-modal .video-wrapper {
        height: 300px !important;
      }
    }

    section.visible-overflow main {
      overflow: visible !important;
    }
  `}</style>
);

export default GlobalStyles;
