import React from "react";
import PropTypes, { string } from "prop-types";
import { Row, Col, Typography } from "antd";
import Image from "../../ImageQuerys/FooterImages";
import SecondaryCta from "../../Buttons/secondary-cta";
import CtaContact from "../../../components-v2/Base/CtaContact";

const { Title, Paragraph } = Typography;

const HelpCenterComponent = ({
  helpCenter,
  className,
  md,
  phoneText,
  phoneNumber,
}) => {
  return (
    <Col xl={10} md={md} className={`footer__help-center ${className} `}>
      <Row>
        <Col xl={12} lg={16} md={18} sm={18} xs={18}>
          <Title level={2} className="title">
            {helpCenter.title}
          </Title>
          <Paragraph className="subtitle">{helpCenter.subTitle}</Paragraph>
        </Col>
        <Col xl={12} lg={8} md={6} sm={6} xs={6} className="image-wrapper">
          <Image className="image" imageName="lifesaver.png" />
        </Col>
        <Paragraph className="text">{helpCenter.text}</Paragraph>
        {phoneNumber && phoneText && (
          <CtaContact
            className="mb-8 lg:mb-0"
            phoneNumber={phoneNumber}
            phoneText={phoneText}
            whiteBackground
          />
        )}
      </Row>
      <Row>
        <Col>
          <SecondaryCta
            className="contact-us"
            ctaTitle={helpCenter.SecondaryCta.title}
            target={helpCenter.SecondaryCta.target}
          />
        </Col>
      </Row>
    </Col>
  );
};

HelpCenterComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  helpCenter: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  md: PropTypes.number,
  phoneNumber: PropTypes.string,
  phoneText: PropTypes.string,
};

HelpCenterComponent.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  sectionData: {},
  className: "",
  md: 12,
  phoneNumber: null,
  phoneText: null,
};

export default HelpCenterComponent;
