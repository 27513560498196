import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { navigate } from "gatsby";

import chevron from "../../images/svg/chevron_left.svg";
import playIcon from "../../images/svg/play.svg";

const CtaPrimary = (props) => {
  const { ctaTitle, target, className, isVideo, onClick, style, openInNewTab } =
    props;

  const handleClick = () =>
    openInNewTab ? window.open(target, "_blank").focus() : navigate(target);

  return (
    <Button
      className={`cta-primary ${className}`}
      type="primary"
      onClick={onClick || handleClick}
      style={style}
    >
      <span className="translate-y-[-3px]">{ctaTitle}</span>
      <img
        src={isVideo ? playIcon : chevron}
        alt="chevron icon"
        style={
          isVideo
            ? { margin: "0px 5px 7px 20px" }
            : { margin: "0px 5px 0px 10px" }
        }
      />
    </Button>
  );
};

CtaPrimary.propTypes = {
  className: PropTypes.string,
  ctaTitle: PropTypes.string,
  target: PropTypes.string,
  isVideo: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  openInNewTab: PropTypes.bool,
};
CtaPrimary.defaultProps = {
  className: "",
  ctaTitle: "Primary CTA",
  target: "/",
  isVideo: false,
  onClick: null,
  style: {},
  openInNewTab: false,
};

export default CtaPrimary;
