import React from "react";
import { viewports, colors } from "../../style-vars";

const { smOrSmaller, mdOrSmaller, lgOrBigger } = viewports;

const GlobalFormStyles = () => (
  <style jsx global>{`
    .form-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
    }

    .lead-form-wrapper.is-landing {
      width: 100%;
      margin-right: 60px;
      margin-top: 60px;
    }

    .lead-form-wrapper__demo-background {
      height: 110%;
      position: absolute;
      width: 100%;
      top: -20px;
      left: 0;
    }

    @media ${viewports.lgOrBigger} {
      .lead-form-wrapper__demo-background {
        display: none;
      }
    }

    .header_wrapper {
      max-width: 440px !important;
    }

    .header_wrapper p {
      color: ${colors.paragraph};
    }

    .lead-form {
      position: relative;
      padding: 40px !important;
      background-color: ${colors.white};
      padding-bottom: 20px !important;
      max-width: 440px !important;
      margin: auto !important;
      margin-top: 0 !important;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
    }
    .lead-form.in-lp {
      max-width: 100% !important;
    }
    .lead-form.in-lp .ant-form-item {
      margin: 0 10px !important;
      margin-bottom: 10px !important;
    }

    .lead-form input,
    .lead-form .ant-select-selector {
      border-radius: 4px !important;
      // border: 1px solid #77819c !important;
    }

    .lead-form input:focus,
    .lead-form .ant-select-selector:focus {
      border: 1px solid ${colors.primary};
      box-shadow: none;
      caret-color: ${colors.primary} !important;
    }

    .lead-form .ant-select-arrow {
      color: #77819c !important;
    }

    .lead-form .ant-form-item {
      margin-bottom: 10px !important;
    }

    .lead-form .label-business-type {
      padding: 5px 10px 2px !important;
      margin-bottom: 0 !important;
    }

    .lead-form.demo-form {
      padding: 0 0 8px !important;
    }

    .ant-form-item-required::before {
      display: none !important;
    }

    .header-img {
      width: 50% !important;
      margin-top: 3% !important;
    }

    .header-img .lp-img {
      height: 580px;
      width: auto;
    }

    .header-img .lp-img img {
      object-position: right center !important;
    }

    .header-img .lp-img.auto-short {
      height: 100%;
    }

    @media ${smOrSmaller} {
      .lead-form-wrapper {
        margin-top: 40px !important;
        max-width: 90%;
        margin: auto;
      }
      .lead-form-wrapper.is-landing {
        max-width: 90%;
        margin: auto;
      }
      .lead-form {
        max-width: 95% !important;
        margin: auto;
      }
    }

    @media ${mdOrSmaller} {
      .lead-form-wrapper.is-landing {
        max-width: 80%;
        margin: auto;
      }

      .header-img {
        width: 100% !important;
      }

      .header-img .lp-img {
        left: auto !important;
        height: 100% !important;
        margin-top: 40px;
      }
    }

    @media ${lgOrBigger} {
      .form-wrapper {
        width: 50%;
        margin: 30px 70px 30px 70px;
      }
      .form-wrapper.is-landing {
        width: 50%;
        margin: 20px 0 0 0;
      }
    }
  `}</style>
);

export default GlobalFormStyles;
