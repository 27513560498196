// IMPORTANT!!!
// Nav components will look up these keys from the keys set in the
// nav-data.js file

import React from "react";

import Others from "../../images/svg/nav-icons/others.inline.svg";
import Reward from "../../images/svg/nav-icons/reward.inline.svg";
import Sales from "../../images/svg/nav-icons/sales.inline.svg";
import Team from "../../images/svg/nav-icons/team.inline.svg";
import Knowledge from "../../images/svg/nav-icons/knowledge.inline.svg";
import Why from "../../images/svg/nav-icons/why.inline.svg";
import Restaurants from "../../images/svg/nav-icons/restaurants.inline.svg";
import FineDining from "../../images/svg/nav-icons/restaurants-fine-dining.inline.svg";
import CasualDining from "../../images/svg/nav-icons/restaurants-casual-dining.inline.svg";
import QSR from "../../images/svg/nav-icons/restaurants-qsr.inline.svg";
import FoodStore from "../../images/svg/nav-icons/restaurants-food-store.inline.svg";
import FoodTruck from "../../images/svg/nav-icons/restaurants-food-truck.inline.svg";
import BarsAndNightclubs from "../../images/svg/nav-icons/restaurants-bars-nightclubs.inline.svg";
import Automotive from "../../images/svg/nav-icons/automotive.inline.svg";
import OilChangeShop from "../../images/svg/nav-icons/automotive-oil-change-shop.inline.svg";
import CarLots from "../../images/svg/nav-icons/automotive-car-lots.inline.svg";
import AutoBody from "../../images/svg/nav-icons/automotive-auto-body.inline.svg";
import AutoParts from "../../images/svg/nav-icons/automotive-auto-parts.inline.svg";
import TintShop from "../../images/svg/nav-icons/automotive-tint-shop.inline.svg";
import AutoSales from "../../images/svg/nav-icons/automotive-auto-sales.inline.svg";
import AutoRepair from "../../images/svg/nav-icons/automotive-auto-repair.inline.svg";
import TireShop from "../../images/svg/nav-icons/automotive-tire-shop.inline.svg";
import VehicleWraps from "../../images/svg/nav-icons/automotive-vehicle-wraps.inline.svg";
import TowTrucks from "../../images/svg/nav-icons/automotive-tow-trucks.inline.svg";
import HealthAndBeauty from "../../images/svg/nav-icons/health-beauty.inline.svg";
import NailSalon from "../../images/svg/nav-icons/health-beauty-nail-salon.inline.svg";
import Barber from "../../images/svg/nav-icons/health-beauty-barber.inline.svg";
import Dentist from "../../images/svg/nav-icons/health-beauty-dentist.inline.svg";
import HairSalon from "../../images/svg/nav-icons/health-beauty-hair-salon.inline.svg";
import Chiropractor from "../../images/svg/nav-icons/health-beauty-chiropractor.inline.svg";
import Vet from "../../images/svg/nav-icons/health-beauty-vet.inline.svg";
import TanningSalon from "../../images/svg/nav-icons/health-beauty-tanning-salon.inline.svg";
import Acupuncture from "../../images/svg/nav-icons/health-beauty-acupuncture.inline.svg";
import PetBoarding from "../../images/svg/nav-icons/health-beauty-pet-boarding.inline.svg";
import ProfessionalServices from "../../images/svg/nav-icons/prof-services.inline.svg";
import Accountant from "../../images/svg/nav-icons/prof-services-accountant.inline.svg";
import Lawyer from "../../images/svg/nav-icons/prof-services-lawyer.inline.svg";
import Appraiser from "../../images/svg/nav-icons/prof-services-appraiser.inline.svg";
import Plumber from "../../images/svg/nav-icons/prof-services-plumber.inline.svg";
import Handyman from "../../images/svg/nav-icons/prof-services-handyman.inline.svg";
import Architect from "../../images/svg/nav-icons/prof-services-architect.inline.svg";
import Locksmith from "../../images/svg/nav-icons/prof-services-locksmith.inline.svg";
import Landscaper from "../../images/svg/nav-icons/prof-services-landscaper.inline.svg";
import Retail from "../../images/svg/nav-icons/retail.inline.svg";
import HomeDecor from "../../images/svg/nav-icons/retail-home-decor.inline.svg";
import SportingGoods from "../../images/svg/nav-icons/retail-sporting-goods.inline.svg";
import ClothingAndApparel from "../../images/svg/nav-icons/retail-clothing.inline.svg";
import Appointments from "../../images/svg/nav-icons/products-appointments.inline.svg";
import Payments from "../../images/svg/nav-icons/products-payments.inline.svg";
import ECommerce from "../../images/svg/nav-icons/products-e-commerce.inline.svg";
import OnlineOrdering from "../../images/svg/nav-icons/products-online-ordering.inline.svg";
import Reporting from "../../images/svg/nav-icons/products-reporting.inline.svg";
import TerminalPos from "../../images/svg/nav-icons/products-terminal-pos.inline.svg";
import Reserve from "../../images/svg/nav-icons/products-reserve.inline.svg";
import VirtualTerminal from "../../images/svg/nav-icons/products-virtual-terminal.inline.svg";
import Marketing from "../../images/svg/nav-icons/products-marketing.inline.svg";
import Capital from "../../images/svg/nav-icons/products-capital.inline.svg";
import ReviewManagement from "../../images/svg/nav-icons/products-review-management.inline.svg";
import Loyalty from "../../images/svg/nav-icons/products-loyalty.inline.svg";
import DeliveryLogistics from "../../images/svg/nav-icons/products-delivery-logistics.inline.svg";
import Payroll from "../../images/svg/nav-icons/products-payroll.inline.svg";
import Delivery from "../../images/svg/nav-icons/products-delivery.inline.svg";
import Website from "../../images/svg/nav-icons/products-website.inline.svg";
import Websites from "../../images/svg/nav-icons/products-websites.inline.svg";
import Pos from "../../images/svg/nav-icons/products-pos.inline.svg";
import Hardware from "../../images/svg/nav-icons/products-hardware.inline.svg";
import Report from "../../images/svg/nav-icons/products-report.inline.svg";
import Revenue from "../../images/svg/nav-icons/products-revenue.inline.svg";
import GiftCard from "../../images/svg/nav-icons/products-gift-card.inline.svg";
import Discount from "../../images/svg/nav-icons/discount.inline.svg";
import KitchenDisplays from "../../images/svg/nav-icons/kitchen-displays.inline.svg";
import OnlineReservation from "../../images/svg/nav-icons/online-reservation.inline.svg";
import Enterprise from "../../images/svg/nav-icons/enterprise.inline.svg";
import Kiosk from "../../images/svg/nav-icons/products-kiosk.inline.svg";
import Handhelds from "../../images/svg/nav-icons/products-handhelds.inline.svg";
import SportsAndEntertainment from "../../images/svg/nav-icons/sports-entertainment.inline.svg";
import Campuses from "../../images/svg/nav-icons/campuses.inline.svg";
import Attractions from "../../images/svg/nav-icons/attractions.inline.svg";
import FoodServices from "../../images/svg/nav-icons/food-services.inline.svg";
import ResourceOverview from "../../images/svg/nav-icons/r-overview.inline.svg";
import ResourceWhitePapers from "../../images/svg/nav-icons/r-white-papers.inline.svg";
import ResourceEbooks from "../../images/svg/nav-icons/r-ebooks.inline.svg";
import ResourceWebinars from "../../images/svg/nav-icons/r-webinars.inline.svg";
import ResourceCaseStudies from "../../images/svg/nav-icons/r-case-studies.inline.svg";
import ResourceBlog from "../../images/svg/nav-icons/r-blog.inline.svg";
import ResourceInfographics from "../../images/svg/nav-icons/r-infographics.inline.svg";
import ResourceTools from "../../images/svg/nav-icons/r-tools.inline.svg";
import ResourceDeveloperCenter from "../../images/svg/nav-icons/r-developer-center.inline.svg";

const keyValueArray = [
  ["others", <Others />],
  ["reward", <Reward />],
  ["sales", <Sales />],
  ["team", <Team />],
  ["knowledge", <Knowledge />],
  ["why", <Why />],
  ["restaurants", <Restaurants />],
  ["restaurants-overview", <Others />],
  ["restaurants-fine-dining", <FineDining />],
  ["restaurants-casual-dining", <CasualDining />],
  ["restaurants-qsr", <QSR />],
  ["restaurants-food-store", <FoodStore />],
  ["restaurants-food-truck", <FoodTruck />],
  ["restaurants-bars-and-nightclubs", <BarsAndNightclubs />],
  ["restaurants-advisory-council", <Why />],
  ["automotive", <Automotive />],
  ["automotive-overview", <Others />],
  ["automotive-oil-change-shop", <OilChangeShop />],
  ["automotive-car-lots", <CarLots />],
  ["automotive-auto-body", <AutoBody />],
  ["automotive-auto-parts", <AutoParts />],
  ["automotive-tint-shop", <TintShop />],
  ["automotive-auto-sales", <AutoSales />],
  ["automotive-auto-repair", <AutoRepair />],
  ["automotive-tire-shop", <TireShop />],
  ["automotive-vehicle-wraps", <VehicleWraps />],
  ["automotive-tow-trucks", <TowTrucks />],
  ["health-and-beauty", <HealthAndBeauty />],
  ["health-and-beauty-overview", <Others />],
  ["health-and-beauty-nail-salon", <NailSalon />],
  ["health-and-beauty-barber", <Barber />],
  ["health-and-beauty-dentist", <Dentist />],
  ["health-and-beauty-hair-salon", <HairSalon />],
  ["health-and-beauty-chiropractor", <Chiropractor />],
  ["health-and-beauty-veterinarian", <Vet />],
  ["health-and-beauty-tanning-salon", <TanningSalon />],
  ["health-and-beauty-acupuncture", <Acupuncture />],
  ["health-and-beauty-pet-boarding", <PetBoarding />],
  ["professional-services", <ProfessionalServices />],
  ["professional-services-overview", <Others />],
  ["professional-services-accountant", <Accountant />],
  ["professional-services-lawyer", <Lawyer />],
  ["professional-services-appraiser", <Appraiser />],
  ["professional-services-plumber", <Plumber />],
  ["professional-services-handyman", <Handyman />],
  ["professional-services-architect", <Architect />],
  ["professional-services-locksmith", <Locksmith />],
  ["professional-services-landscaper", <Landscaper />],
  ["retail", <Retail />],
  ["retail-overview", <Others />],
  ["retail-home-decor", <HomeDecor />],
  ["retail-sporting-goods", <SportingGoods />],
  ["retail-clothing-and-apparel", <ClothingAndApparel />],
  ["products-payments-overview", <Payments />],
  ["products-restaurant", <Restaurants />],
  ["products-retail", <Retail />],
  ["products-food-truck", <FoodTruck />],
  ["products-appointments", <Appointments />],
  ["products-payments", <Payments />],
  ["products-e-commerce", <ECommerce />],
  ["products-customers-e-commerce", <ECommerce />],
  ["products-online-ordering", <OnlineOrdering />],
  ["products-reporting", <Reporting />],
  ["products-terminal-pos", <TerminalPos />],
  ["products-reserve", <Reserve />],
  ["products-customers-reserve", <Reserve />],
  ["products-virtual-terminal", <VirtualTerminal />],
  ["products-marketing", <Marketing />],
  ["products-capital", <Capital />],
  ["products-review-management", <ReviewManagement />],
  ["products-loyalty-overview", <Loyalty />],
  ["products-loyalty", <Loyalty />],
  ["products-delivery-logistics", <DeliveryLogistics />],
  ["products-payroll", <Payroll />],
  ["products-delivery", <Delivery />],
  ["products-order-delivery", <Delivery />],
  ["labor-management", <VirtualTerminal />],
  ["products-customers-order-delivery", <Delivery />],
  ["products-website", <Website />],
  ["products-websites", <Websites />],
  ["products-pos", <Pos />],
  ["products-hardware", <Hardware />],
  ["products-report", <Report />],
  ["products-revenue", <Revenue />],
  ["products-gift-card", <GiftCard />],
  ["discount", <Discount />],
  ["kitchen-displays", <KitchenDisplays />],
  ["online-reservation", <OnlineReservation />],
  ["products-experiences-at-scale", <Enterprise />],
  ["bt-enterprise", <Enterprise />],
  ["products-scale-kiosks", <Kiosk />],
  ["products-scale-pos-terminals", <TerminalPos />],
  ["products-scale-online-ordering", <VirtualTerminal />],
  ["products-scale-handhelds", <Handhelds />],
  ["products-scale-payment-devices", <Payments />],
  ["products-scale-back-of-house-management", <Revenue />],
  ["products-scale-kitchen-displays", <KitchenDisplays />],
  ["bt-enterprise-overview", <DeliveryLogistics />],
  ["bt-enterprise-sports", <SportsAndEntertainment />],
  ["bt-enterprise-campuses", <Campuses />],
  ["bt-enterprise-facilities", <ProfessionalServices />],
  ["bt-enterprise-attractions", <Attractions />],
  ["bt-enterprise-food-services", <FoodServices />],
  ["bt-enterprise-retail", <Retail />],
  ["r-overview", <ResourceOverview />],
  ["r-white-papers", <ResourceWhitePapers />],
  ["r-ebooks", <ResourceEbooks />],
  ["r-videos", <ResourceWebinars />],
  ["r-case-studies", <ResourceCaseStudies />],
  ["r-blog", <ResourceBlog />],
  ["r-infographics", <ResourceInfographics />],
  ["r-tools", <ResourceTools />],
  ["r-developer-center", <ResourceDeveloperCenter />],
];

const icons = new Map(keyValueArray);

export default icons;
