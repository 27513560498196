import React, { useEffect, useState } from "react";

const useShouldHideDemoCta = () => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const { pathname } = window.location;
    setHidden(pathname && (pathname.includes("thank-you") || pathname.includes("/demo")));
  }, []);

  return hidden;
};

export default useShouldHideDemoCta;
