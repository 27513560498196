import React from "react";
import { string, bool } from "prop-types";
import phone from "../../../images/svg/phone.svg";

const CtaContact = ({ className, phoneNumber, phoneText, whiteBackground }) => {
  return (
    <a
      href={`tel:${phoneNumber}`}
      className={`
        ${className}
        flex flex-row items-end
        rounded-full xl:text-cta
        px-4 xl:px-6 py-2.5
        ${whiteBackground ? "bg-white" : "bg-black-800"}
      `}
    >
      <img src={phone} alt="phone icon" className="mr-2.5" />
      <div className="leading-tight">{phoneText}</div>
    </a>
  );
};

CtaContact.propTypes = {
  className: string,
  phoneNumber: string.isRequired,
  phoneText: string.isRequired,
  whiteBackground: bool,
};

CtaContact.defaultProps = {
  className: "",
  whiteBackground: false,
};

export default CtaContact;
