import React from "react";
import { bool, number, oneOfType } from "prop-types";
import { colors, viewports } from "../../style-vars";

const DesktopNavStyles = ({ addScrollOnNav, windowHeight }) => (
  <style jsx global>{`
    .desktop-nav,
    .desktop-nav__items-wrapper {
      display: none;
    }

    @media ${viewports.lgOrBigger} {
      .desktop-nav {
        z-index: 10;
        //max-width: 1440px;
        height: 72px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .desktop-nav__logo {
        flex: 0 1 15%;
        z-index: 10;
      }
      .desktop-nav__ctas {
        flex: 0 1 25%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        z-index: 10;
      }
      .desktop-nav__items {
        height: 100%;
        flex: 0 1 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
      }

      button.desktop-nav__item {
        background: none;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
      }
      button.desktop-nav__item:hover {
        cursor: pointer;
      }
      a.desktop-nav__item {
        line-height: 38px;
      }
      .desktop-nav__item {
        z-index: 10;
        height: 100%;
        font-size: 16px;
        color: #090d12;
        margin: 0 5px;
        padding: 15px 10px;
        border-bottom: 3px solid transparent !important;
        //transition: 0.2s;
      }
      .desktop-nav__item:hover,
      .desktop-nav__item.active {
        border-bottom-color: ${colors.primary} !important;
      }
      .desktop-nav__item--arrow {
        margin-left: 10px;
        transform: rotate(90deg);
        transition: 0.3s ease-in-out;
      }
      .desktop-nav__item.active .desktop-nav__item--arrow {
        transform: rotate(-90deg);
      }

      .desktop-nav__items-wrapper {
        position: fixed;
        width: 100%;
        top: 152px;
        left: 0;
        background: transparent;
        display: flex;
        height: auto;
        z-index: 9;
      }
      body.demo-page .desktop-nav__items-wrapper {
        top: 72px !important;
      }

      @media ${viewports.maxContentWidthOrBigger} {
        .desktop-nav__items-wrapper {
          max-width: 1440px;
          left: 50%;
          margin-left: -720px;
        }
      }

      @media only screen and (min-width: 1980px) {
        .desktop-nav__items-wrapper {
          max-height: 70vh;
        }
      }
      @media only screen and (min-width: 1200px) {
        .desktop-nav__items-wrapper {
          top: 132px !important;
        }
      }

      .desktop-nav__left {
        background: ${colors.white};
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 32px 0 30px 0;
        flex: 0 1 40%;
        overflow-y: scroll;
        height: ${addScrollOnNav ? `${windowHeight - 150}px` : "auto"};
      }
      .desktop-nav__items-wrapper.horizontal .desktop-nav__left {
        flex: 0 0 100%;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: baseline;
        justify-content: center;
      }

      .desktop-nav__right {
        background: ${colors.white};
        //overflow-y: auto;
        //max-height: 100%;
        //position: fixed;
        //top: 72px;
        //left: 45vw;
        //z-index: 8;
        //min-height: 80vh;
        //min-width: 55vw;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: baseline;
        justify-content: space-between;
        box-shadow: inset 32px 0px 44px rgba(0, 0, 0, 0.06);
        padding: 32px 47px 30px 47px;
        flex: 0 1 60%;
      }

      .desktop-nav__ctas--login-wrapper {
        position: relative;
        height: 100%;
      }
      .desktop-nav__ctas--login {
        background: none;
        outline: none;
        border: none;
        font-size: 16px;
        color: ${colors.primary};
        height: 100%;
        border-bottom: 3px solid transparent;
      }
      .desktop-nav__ctas--login:hover {
        cursor: pointer;
        border-bottom-color: ${colors.primary};
      }
      .desktop-nav__login-buttons {
        position: absolute;
        padding: 8px 0;
        background: ${colors.white};
        width: 200px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        z-index: 10;
        box-shadow: 0px 6px 80px rgba(0, 0, 0, 0.18),
          0px 2.50666px 33.4221px rgba(0, 0, 0, 0.129394),
          0px 1.34018px 17.869px rgba(0, 0, 0, 0.107299),
          0px 0.751293px 10.0172px rgba(0, 0, 0, 0.09),
          0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0727007),
          0px 0.166035px 2.21381px rgba(0, 0, 0, 0.0506062);
      }

      .desktop-nav__login-buttons a {
        font-size: 14px;
        font-weight: bold;
        color: ${colors.black};
        padding: 8px 16px;
        border-radius: 3px;
      }
      .desktop-nav__login-buttons a:hover {
        background: #e8f0ff;
      }

      .desktop-nav__ctas .cta-primary {
        margin-left: 30px !important;
      }

      .header.transparent .desktop-nav__item {
        color: ${colors.white};
      }
      .header.transparent .desktop-nav__ctas--login {
        color: ${colors.white};
      }
      .header.transparent .desktop-nav__item--arrow rect {
        fill: transparent;
      }
      .header.transparent .desktop-nav__item--arrow path {
        fill: ${colors.white};
      }

      .header.transparent.nav-visible .desktop-nav__item {
        color: #090d12;
      }
      .header.transparent.nav-visible .desktop-nav__ctas--login {
        color: #090d12;
      }
      .header.transparent.nav-visible .desktop-nav__item--arrow rect {
        fill: #e8f0ff;
      }
      .header.transparent.nav-visible .desktop-nav__item--arrow path {
        fill: ${colors.primary};
      }
    }

    @media ${viewports.maxContentWidthOrBigger} {
      .desktop-nav__logo {
        flex: 0 1 19%;
      }
      .desktop-nav__items {
        flex: 0 1 70%;
      }
      .desktop-nav__ctas {
        flex: 0 1 21%;
      }
    }

    .desktop-nav__items-wrapper.horizontal .desktop-nav__left .main-nav-item {
      width: 350px;
      margin: 8px;
    }
    .desktop-nav__items-wrapper.horizontal .desktop-nav__right {
      display: none;
    }
  `}</style>
);

DesktopNavStyles.propTypes = {
  addScrollOnNav: oneOfType([bool, number]),
  windowHeight: number,
};

DesktopNavStyles.defaultProps = {
  addScrollOnNav: false,
  windowHeight: 0,
};

export default DesktopNavStyles;
