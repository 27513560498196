import { pagesWithDemoForms } from "../constants";

const urlIndustryToFormNameMap = new Map([
  ["restaurants", "restaurants"],
  ["automotive", "auto"],
  ["health-and-beauty", "health-and-beauty"],
  ["professional-services", "professional-services"],
  ["retail", "retail"],
]);

const addSlash = (str) => {
  if (!str) {
    return;
  }

  return str.slice(-1) === "/" ? str : `${str}/`;
};

export function getDemoUrl(path) {
  // if (path === "/demo" || path === "/demo/") {
  if (/\/demo/.test(path)) {
    return null;
  }
  if (path === "/") {
    return "/demo";
  }
  if (path.includes("/legal/")) {
    return "/demo";
  }
  if (path.includes("/about-us")) {
    return "/demo";
  }
  if (path.includes("/careers")) {
    return "/demo";
  }
  if (path.includes("/company")) {
    return "/demo";
  }
  if (path.includes("/media")) {
    return "/demo";
  }
  if (path.includes("/pointsofprofit")) {
    return "/demo";
  }
  if (path.includes("/resource-center") || path.includes("/case-studies")) {
    return "/demo";
  }
  if (path.includes("company/advisory-board")) {
    return "/restaurant-pos/demo";
  }

  // Remove when A/B test is complete
  if (path.includes("restaurant-pos/new")) {
    return "/restaurant-pos/learn-more";
  }

  const regexp = new RegExp(path);
  const pageHasDemo = pagesWithDemoForms.find((page) => {
    return regexp.test(page);
  });

  const pathWithSlash = addSlash(path);

  return pageHasDemo ? `${pathWithSlash}demo` : "demo";
}

export function getThankYouUrl(path = "") {
  const regexp = new RegExp(addSlash(path));
  const pageHasDemo = pagesWithDemoForms.find((page) => {
    return regexp.test(`${addSlash(page)}demo/`);
  });

  return pageHasDemo ? path.replace(/\/demo/, "/thank-you") : null;
}

export function mapUrlIndustryToFormValue(industry) {
  return urlIndustryToFormNameMap.get(industry) || null;
}

export function dynamicHeadlines() {
  let header;
  if (typeof window !== `undefined`) {
    const getUrlParams = (search) => {
      const hashes = search.slice(search.indexOf("?") + 1).split("&");
      const params = {};
      // eslint-disable-next-line
      hashes.map(hash => {
        const [key, val] = hash.split("=");
        params[key] = decodeURIComponent(val);
      });
      return params;
    };
    const headline = getUrlParams(window.location.search).h1;
    if (typeof headline !== `undefined`) {
      const headerArr = headline.toString().split(" ");
      const headerCap = [];
      headerArr.forEach((el) =>
        headerCap.push(el.charAt(0).toUpperCase() + el.substring(1))
      );
      header = headerCap.join(" ");
    }
    return header;
  }
  return header;
}
