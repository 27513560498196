import React, { useState, useEffect, useContext } from "react";
import { string, bool, number } from "prop-types";
import DesktopNav from "./desktop-nav";
import HeaderStyles from "./styles";
import HeaderContext from "./header-context";
import useGetViewportY from "../../hooks/use-get-viewport-y";
import MobileNav from "./mobile-nav";
import AppContext from "../../components/app-context";
// import CustomBanner from '../../components/CustomBanner';
// import { NewAquisition } from '../../data/Misc/BannerData';
import useWindowSize from "../../hooks/use-window-size";
import DesktopSubNav from "./desktop-sub-nav";

const Header = ({
  className,
  transparentHeader,
  noTopOffset,
  phoneNumber,
  phoneText,
  // eslint-disable-next-line react/prop-types
}) => {
  const [hover, setHover] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [activeMainNavItemKey, setActiveMainNavItemKey] = useState("");
  const [mainNavItems, setMainNavItems] = useState([]);
  const [mainNavSubItems, setMainNavSubItems] = useState([]);
  const [pagePath, setPagePath] = useState("");
  const [subItemsTitle, setSubItemsTitle] = useState("");
  const [lgOrSmaller, setLgOrSmaller] = useState(false);
  const [hasMobileNav, setHasMobileNav] = useState(false);

  const viewportY = useGetViewportY();
  const { navVisible, setNavVisible } = useContext(AppContext);
  const size = useWindowSize();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setPagePath(window.location.pathname);
    }
    if (size.width < 1200) {
      setLgOrSmaller(true);
    }
    if (size.width < 992) {
      setHasMobileNav(true);
    }
  }, []);

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  const shouldBeTransparent = Math.abs(viewportY) <= 100 && transparentHeader;

  const context = {
    activeKey,
    setActiveKey,
    activeMainNavItemKey,
    setActiveMainNavItemKey,
    mainNavItems,
    setMainNavItems,
    mainNavSubItems,
    setMainNavSubItems,
    pagePath,
    subItemsTitle,
    setSubItemsTitle,
    hasMobileNav,
  };

  const isTransparentHeader =
    (shouldBeTransparent && !hover) ||
    (shouldBeTransparent && size.width < 992);

  return (
    <HeaderContext.Provider value={context}>
      <header
        role="banner"
        className={`header ${className} ${
          isTransparentHeader ? "transparent" : ""
        } ${navVisible ? "nav-visible" : ""}`}
        style={{
          /* eslint-disable-next-line no-nested-ternary */
          top: noTopOffset ? 0 : lgOrSmaller ? 80 : 60,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* <CustomBanner customData={NewAquisition} /> */}
        <MobileNav
          transparentHeader={shouldBeTransparent}
          phoneNumber={phoneNumber}
          phoneText={phoneText}
        />
        <DesktopNav
          transparentHeader={shouldBeTransparent && !hover}
          phoneNumber={phoneNumber}
          phoneText={phoneText}
        />
        <HeaderStyles />
      </header>
      <DesktopSubNav />
    </HeaderContext.Provider>
  );
};

Header.propTypes = {
  className: string,
  transparentHeader: bool,
  noTopOffset: number,
  phoneNumber: string,
  phoneText: string,
};

Header.defaultProps = {
  className: "",
  transparentHeader: false,
  noTopOffset: null,
  phoneNumber: null,
  phoneText: null,
};

export default Header;
