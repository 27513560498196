import React from "react";
import { viewports, colors } from "../../style-vars";

const { smOrSmaller, mdOrSmaller, lgOrSmaller, xsOnly } = viewports;
const { white } = colors;

const CustomBannerStyles = () => (
  <style jsx>{`
    .custom-banner {
      position: fixed;
      padding: 13px 15%;
      width: 100%;
      top: 0;
      left: 0;
      background: ${white};
      z-index: 999;
      overflow: hidden;
      min-height: 65px;
    }
    .custom-inline-banner {
      position: fixed;
      padding: 13px 15%;
      width: 100%;
      top: 0;
      left: 0;
      background: ${white};
      z-index: 999;
      overflow: hidden;
      height: 72px;
    }
    .banner-bold {
      font-weight: 800;
      font-size: 18px !important;
      color: #000000 !important;
    }
    .banner-cta {
      font-size: 18px !important;
    }
    .banner-image-left,
    .banner-image-right {
      width: 260px;
      height: 75px;
      position: absolute;
    }
    .banner-image-right {
      right: 0;
      bottom: 26px;
    }
    .inline-grid {
      display: grid;
      grid-template-columns: 3fr 1fr;
    }
    .banner-image-left {
      top: -36%;
      left: 0;
    }
    .buttontainer {
      display: table !important;
      margin: 0 auto;
    }
    @media ${lgOrSmaller} {
      .custom-banner {
        height: 80px;
      }
      .banner-image-right {
        right: -10%;
        z-index: -1;
      }
      .banner-image-left {
        left: -10%;
      }
    }
    @media ${mdOrSmaller} {
      .custom-banner {
        padding: 0 8%;
      }
      .custom-inline-banner {
        height: 80px;
        padding: 13px 2%;
      }
      .banner-image-right {
        right: -20%;
        z-index: -1;
      }
      .banner-image-left {
        left: -20%;
        z-index: -1;
      }
      .banner-cta {
        font-size: 13px !important;
      }
      .banner-bold {
        font-size: 16px !important;
        font-weight: 800;
      }
    }
    @media ${smOrSmaller} {
      .custom-banner,
      .custom-inline-banner {
        padding: 0 12px !important;
      }
      .custom-inline-banner {
        height: auto;
      }
      .banner-image-right {
        right: -40%;
        z-index: -1;
      }
      .banner-image-left {
        left: -40%;
        z-index: -1;
        top: 0;
      }
      .banner-cta {
        font-size: 14px !important;
        margin-top: -10px;
      }
      .banner-bold {
        font-size: 16px !important;
        font-weight: 800;
      }
      .inline-grid {
        display: grid;
        grid-template-columns: 1fr;
      }
      .mobile-center {
        display: table;
        margin: 0 auto;
      }
    }
    @media ${xsOnly} {
      .custom-banner {
        padding: 1px 12px !important;
      }
      .banner-bold {
        font-weight: 800;
      }
    }
  `}</style>
);

export default CustomBannerStyles;
