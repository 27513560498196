import React from "react";
import { string, arrayOf, object as obj } from "prop-types";
import { Menu, Col } from "antd";
import { Link } from "gatsby";
import Heading from "../../../components-v2/Base/HeadingBuilder";

const LinkList = ({ title, links, className }) => {
  return (
    <Col xs={24} className={`link-list ${className}`}>
      <Heading level={3} injectionType={5} noMargin>
        {title}
      </Heading>
      <Menu>
        {links.map((link) => (
          <Menu.Item key={link.title}>
            {link.extUrl ? (
              <a href={link.target || ""} target="_blank" rel="noreferrer">
                {link.title}
              </a>
            ) : (
              <Link to={link.target || ""}>{link.title}</Link>
            )}
          </Menu.Item>
        ))}
      </Menu>
    </Col>
  );
};

LinkList.propTypes = {
  title: string,
  links: arrayOf(obj),
  className: string,
};
LinkList.defaultProps = {
  title: "",
  className: "",
  links: {},
};

export default LinkList;
