import React, { useState, useEffect, useContext } from "react";
import { bool, string } from "prop-types";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import useGetViewportY from "../../hooks/use-get-viewport-y";
import Logo from "../../images/svg/SpotOnLogo.svg";
import LogoWhite from "../../images/svg/SpotOnLogoWhite.svg";
import HeaderContext from "../Header/header-context";
import DesktopSubNav from "../Header/desktop-sub-nav";
import HeaderStyles from "../Header/styles";
import DesktopNavStyles from "../Header/desktop-nav-styles";

const HeaderCallback = ({ className, transparentHeader }) => {
  const [shouldBeTransparent, setShouldBeTransparent] = useState(false);
  const [hover, setHover] = useState(false);
  const [activeKey, setActiveKey] = useState("");

  const [loginMenuOpened, setLoginMenuOpened] = useState(false);
  const viewportY = useGetViewportY();

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  useEffect(() => {
    setShouldBeTransparent(Math.abs(viewportY) <= 100 && transparentHeader);
  }, [viewportY]);

  return (
    <header
      className={`${className}
      fixed top-0 left-0 w-full z-20 h-[72px] max-w-[100vw]
      flex items-center justify-between px-4 md:px-10 callback-header
      ${!shouldBeTransparent ? "bg-white" : ""}
    `}
    >
      <div>
        <Link to="/" style={{ margin: "auto" }}>
          <img
            className="header__logo"
            src={shouldBeTransparent ? LogoWhite : Logo}
            alt="logo"
          />
        </Link>
      </div>

      <div className="flex justify-between xl:gap-x-10">
        <div onMouseEnter={() => setActiveKey("")}>
          <button
            type="button"
            className="bg-[transparent] outline-none border-none text-[16px] text-primary h-[100%] translate-x-[10%] md:translate-x-[20%] "
            onClick={() => setLoginMenuOpened((state) => !state)}
          >
            Login
          </button>

          <motion.div
            className="bg-[#ffffff] px-2 w-[150px] rounded-[12px] absolute flex flex-column z-10 shadow-md left-[60%] md:left-[75%] lg:left-[83%] xl:left-[88%] translate-y-[-50%]"
            initial={{ opacity: 0, y: "-300px" }}
            animate={
              loginMenuOpened
                ? { opacity: 1, y: 0 }
                : { opacity: 0, y: "-300px" }
            }
          >
            <ul className="list-none p-0 m-0 block">
              <li className="h-8 mt-2">
                <a href="https://login.spoton.com/home/">as Merchant</a>
              </li>
              <li className="h-8 mt-[6px]">
                <a href="https://thespot.spoton.com/">as Partner</a>
              </li>
            </ul>
          </motion.div>
        </div>
      </div>
      <DesktopNavStyles addScrollOnNav windowHeight={0} />
    </header>
  );
};

HeaderCallback.propTypes = {
  className: string,
  transparentHeader: bool,
};

HeaderCallback.defaultProps = {
  className: "",
  transparentHeader: false,
};

export default HeaderCallback;
