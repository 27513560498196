import React, { useContext, useState, useRef, useEffect } from "react";
import { bool, string } from "prop-types";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import navData from "./nav-data";
import LogoBlack from "../../images/svg/SpotOnLogo.svg";
import LogoWhite from "../../images/svg/SpotOnLogoWhite.svg";
import AppContext from "../../components/app-context";
import HeaderContext from "./header-context";
import ArrowIcon from "../../images/svg/nav-icons/arrow.inline.svg";
import PrimaryCta from "../../components/Buttons/primary-cta";
import { getDemoUrl } from "../../utils/url-utils";
import DesktopNavStyles from "./desktop-nav-styles";
import useWindowSize from "../../hooks/use-window-size";
import useShouldHideDemoCta from "../../hooks/use-should-hide-demo-cta";
import phone from "../../images/svg/phone.svg";
import CtaContact from "../Base/CtaContact";

const DesktopNav = ({
  transparentHeader,
  isDemoURL,
  phoneNumber,
  phoneText,
}) => {
  const [loginMenuOpened, setLoginMenuOpened] = useState(false);
  const [addScrollOnNav, setAddScrollOnNav] = useState(0);
  const [delay, setDelay] = useState(null);
  const navRef = useRef(null);
  const { height } = useWindowSize();
  const hideDemoCta = useShouldHideDemoCta();

  const { navVisible, setNavVisible, setSubItemsVisible } =
    useContext(AppContext);

  const {
    activeKey,
    setActiveKey,
    setActiveMainNavItemKey,
    setMainNavItems,
    setMainNavSubItems,
    pagePath,
  } = useContext(HeaderContext);

  useEffect(() => {
    setAddScrollOnNav(navRef?.current?.clientHeight > height - 130);
  }, [activeKey, height]);

  const onButtonClick = (key, items, isHover = false) => {
    if (!isHover && key === activeKey && navVisible) {
      return setNavVisible(false);
    }

    if (isHover && key !== activeKey) {
      setNavVisible(false);
      setSubItemsVisible(false);
    }

    setTimeout(() => {
      setActiveKey(key);
      if (items.length) {
        setMainNavItems(items);
        setMainNavSubItems(items[0]?.subItems ?? []);
      }
      setActiveMainNavItemKey(items[0]?.key ?? "");
      setNavVisible(true);
      return setSubItemsVisible(key === "business-types" || key === "products");
    }, 100);
  };

  const onLinkClick = (key) => {
    setActiveKey(key);
    setActiveMainNavItemKey("");
    setMainNavItems([]);
    setMainNavSubItems([]);
    setNavVisible(false);
    setSubItemsVisible(false);
  };

  const onMouseEnter = (key, items, isHover) => {
    if (!isMobile) {
      const t = setTimeout(() => {
        onButtonClick(key, items, isHover);
      }, 100);
      setDelay(t);
    }
  };

  const onMouseLeave = () => {
    clearTimeout(delay);
  };

  return (
    <div className="desktop-nav">
      <div className="desktop-nav__logo">
        <Link to="/" onClick={() => onLinkClick("home")}>
          <img
            className="header__logo"
            src={transparentHeader && !navVisible ? LogoWhite : LogoBlack}
            alt="logo"
          />
        </Link>
      </div>

      {/* Main navigation at the top */}
      <nav id="main-nav" className="h-full">
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <ul
          className="list-none m-0 p-0 h-full desktop-nav__items align-center justify-items-center"
          role="list"
        >
          {navData.map(({ label, key, path, items, externalURL }) => {
            const isActive = activeKey === key && navVisible;
            const className = `desktop-nav__item ${isActive ? "active" : ""}`;
            const liActive = isActive ? "li-active" : "";
            if (path) {
              return externalURL ? (
                // eslint-disable-next-line jsx-a11y/no-redundant-roles
                <li
                  key={key}
                  role="listitem"
                  className={`desktop-nav__li h-full flex ${liActive}`}
                >
                  <a
                    className={`${className} self-center`}
                    href={path}
                    onClick={() => onLinkClick(key)}
                    onMouseEnter={() => onLinkClick(key)}
                  >
                    {label}
                  </a>
                </li>
              ) : (
                // eslint-disable-next-line jsx-a11y/no-redundant-roles
                <li
                  key={key}
                  role="listitem"
                  className={`desktop-nav__li h-full flex ${liActive}`}
                >
                  <Link
                    to={path}
                    className={`${className} self-center`}
                    onClick={() => onLinkClick(key)}
                    onMouseEnter={() => onLinkClick(key)}
                  >
                    {label}
                  </Link>
                </li>
              );
            }
            return (
              // eslint-disable-next-line jsx-a11y/no-redundant-roles
              <li
                role="listitem"
                className={`desktop-nav__li h-full ${liActive}`}
              >
                <button
                  key={key}
                  type="button"
                  className={className}
                  onClick={() => onButtonClick(key, items)}
                  onMouseEnter={() => onMouseEnter(key, items, true)}
                  onMouseLeave={onMouseLeave}
                >
                  {label}
                  <ArrowIcon className="desktop-nav__item--arrow" />
                </button>
              </li>
            );
          })}
        </ul>
      </nav>

      <div className="desktop-nav__ctas" onMouseEnter={() => setActiveKey("")}>
        <div className="desktop-nav__ctas--login-wrapper mr-6">
          <button
            type="button"
            className="desktop-nav__ctas--login"
            onClick={() => setLoginMenuOpened((state) => !state)}
          >
            Login
          </button>

          <motion.div
            className="desktop-nav__login-buttons"
            initial={{ opacity: 0, y: "-300px" }}
            animate={
              loginMenuOpened
                ? { opacity: 1, y: 0 }
                : { opacity: 0, y: "-300px" }
            }
          >
            <ul className="list-none p-0 m-0 block">
              <li className="h-8 mt-2">
                <a href="https://login.spoton.com/home/">as Merchant</a>
              </li>
              <li className="h-8 mt-[6px]">
                <a href="https://thespot.spoton.com/">as Partner</a>
              </li>
            </ul>
          </motion.div>
        </div>

        {!phoneNumber && !phoneText && !hideDemoCta && (
          <PrimaryCta
            className="get-your-demo"
            ctaTitle="Learn more"
            target={
              // eslint-disable-next-line no-nested-ternary
              pagePath === "/products/reserve"
                ? `/products-demo`
                : typeof window !== "undefined"
                ? getDemoUrl(window.location.pathname)
                : "/demo"
            }
          />
        )}

        {phoneNumber && phoneText && (
          <CtaContact phoneNumber={phoneNumber} phoneText={phoneText} />
        )}
      </div>

      <DesktopNavStyles
        addScrollOnNav={addScrollOnNav}
        windowHeight={height ?? 0}
      />
    </div>
  );
};

DesktopNav.propTypes = {
  transparentHeader: bool,
  isDemoURL: bool,
  phoneNumber: string,
  phoneText: string,
};

DesktopNav.defaultProps = {
  transparentHeader: false,
  isDemoURL: false,
  phoneNumber: null,
  phoneText: null,
};

export default DesktopNav;
