import { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const useGetViewportY = () => {
  const [currentY, setCurrentY] = useState(0);

  useScrollPosition(({ prevPos, currPos }) => {
    setCurrentY(currPos.y);
  });

  return currentY;
};

export default useGetViewportY;
