import { resourceCategories } from "../../constants";

const navData = [
  {
    label: "Home",
    key: "home",
    path: "/",
  },
  {
    label: "Products",
    key: "products",
    items: [
      {
        label: "Take Payments Everywhere",
        description:
          "In store, online, and on the road—connect with customers and get paid.",
        key: "products-payments-overview",
        subItems: [
          {
            label: "SpotOn Restaurant",
            description:
              "More than a POS. A better way to run your restaurant.",
            key: "products-restaurant",
            path: "/restaurant-pos/",
          },
          {
            label: "SpotOn Retail",
            description: "Sell in-store and online, effortlessly.",
            key: "products-retail",
            path: "/retail/",
          },
          {
            label: "SpotOn Order & Delivery",
            description:
              "Commission-free online ordering, QR ordering, and delivery.",
            key: "products-delivery",
            path: "/products/online-order-and-delivery/",
          },
          {
            label: "Sidekick for Food Trucks",
            description: "Portable POS for food trucks and more.",
            key: "products-food-truck",
            path: "/food-truck-pos/",
          },
          {
            label: "Mobile & Virtual Terminal",
            description: "Take payments your way. Wherever business takes you.",
            key: "products-virtual-terminal",
            path: "/products/virtual-terminal/",
          },
          {
            label: "Payment processing",
            description:
              "Fair pricing. No junk fees. Better tools to do business.",
            key: "products-payments",
            path: "/products/payments/",
          },
        ],
      },
      {
        label: "Deliver experiences at scale",
        description:
          "Omnichannel commerce solutions for enterprise businesses.",
        key: "products-experiences-at-scale",
        subItems: [
          {
            label: "Enterprise Venue Kiosks",
            description:
              "Shorten lines and increase revenue with self-service technology.",
            key: "products-scale-kiosks",
            path: "/enterprise/kiosks/",
          },
          {
            label: "Point-of-Sale",
            description: "Speed up service with a secure, cloud-based POS.",
            key: "products-scale-pos-terminals",
            path: "/enterprise/pos-terminals/",
          },
          {
            label: "Mobile & Online Ordering ",
            description:
              "Increase sales by letting guests order online or in-app, from any device.",
            key: "products-scale-online-ordering",
            path: "/enterprise/mobile-and-online-ordering/",
          },
          {
            label: "Handhelds",
            description:
              "Serve customers anywhere—faster and with higher average ticket prices.",
            key: "products-scale-handhelds",
            path: "/enterprise/handhelds/",
          },
          {
            label: "Payment Devices and Accessories",
            description:
              "Sell confidently with robust hardware built for high-volume enterprises.",
            key: "products-scale-payment-devices",
            path: "/enterprise/payment-devices/",
          },
          {
            label: "Back-of-House Management",
            description:
              "Get better data and control your entire operation from one convenient dashboard.",
            key: "products-scale-back-of-house-management",
            path: "/enterprise/back-of-house-management/",
          },
          {
            label: "Kitchen Displays",
            description:
              "Increase kitchen efficiency and get orders out faster.",
            key: "products-scale-kitchen-displays",
            path: "/enterprise/kitchen-displays/",
          },
        ],
      },
      {
        label: "Grow Revenue & Reduce Costs",
        description:
          "Integrated software & services to streamline operations and increase sales.",
        key: "products-revenue",
        subItems: [
          {
            label: "SpotOn Reserve",
            description:
              "Digital reservations and waitlisting with guest safety in mind.",
            key: "products-reserve",
            path: "/products/reserve/",
          },
          {
            label: "Ecommerce",
            description:
              "Sell online with a custom site at a do-it-yourself price.",
            key: "products-e-commerce",
            path: "/products/e-commerce/",
          },
          {
            label: "Reporting",
            description: "Work smarter with better data at your fingertips.",
            key: "products-reporting",
            path: "/products/reporting/",
          },
          {
            label: "Marketing",
            description:
              "Email and social media marketing to promote your business like a pro.",
            key: "products-marketing",
            path: "/products/marketing/",
          },
          {
            label: "Loyalty",
            description:
              "Create custom rewards to drive repeat visits & revenue.",
            key: "products-loyalty",
            path: "/products/loyalty/",
          },
          {
            label: "SpotOn Capital",
            description: "Get the loan you need. Your way.",
            key: "products-capital",
            path: "/products/capital/",
          },
          {
            label: "SpotOn Order & Delivery",
            description:
              "Commission-free online ordering, QR ordering, and food delivery.",
            key: "products-order-delivery",
            path: "/products/online-order-and-delivery/",
          },
          {
            label: "Labor Management",
            description:
              "Simplify labor management with Dolce and SpotOn Restaurant.",
            key: "labor-management",
            path: "/products/labor-management/",
          },
        ],
      },
      {
        label: "Reach More Customers",
        description:
          "Digital solutions to take advantage of changing customer trends.",
        key: "products-loyalty-overview",
        subItems: [
          {
            label: "Appointments",
            description: "Book more appointments and streamline scheduling.",
            key: "products-appointments",
            path: "/products/appointments/",
          },
          {
            label: "Ecommerce",
            description:
              "Reach more customers with a hassle-free online store.",
            key: "products-customers-e-commerce",
            path: "/products/e-commerce/",
          },
          {
            label: "Website",
            description: "Get a custom site for a do-it-yourself price.",
            key: "products-website",
            path: "/products/website/",
          },
          {
            label: "SpotOn Order & Delivery",
            description:
              "Commission-free online ordering, QR ordering, and food delivery.",
            key: "products-customers-order-delivery",
            path: "/products/online-order-and-delivery/",
          },
          {
            label: "Review Management",
            description: "Take control of your online reputation.",
            key: "products-review-management",
            path: "/products/review-management/",
          },
          {
            label: "SpotOn Reserve",
            description:
              "Digital reservations and waitlisting with guest safety in mind.",
            key: "products-customers-reserve",
            path: "/products/reserve/",
          },
        ],
      },
    ],
  },
  {
    label: "Business types",
    key: "business-types",
    items: [
      {
        label: "Restaurants & Hospitality",
        description:
          "Tech to increase sales, gain better data, and streamline operations.",
        key: "restaurants",
        subItems: [
          {
            label: "Overview",
            description: "More than POS. A better way to run your restaurant.",
            key: "restaurants-overview",
            path: "/restaurant-pos/",
          },
          {
            label: "Fine Dining",
            description: "Tailor-made tech for fine dining establishments.",
            key: "restaurants-fine-dining",
            path: "/fine-dining-pos/",
          },
          {
            label: "Bars & Nightclubs",
            description: "Top-shelf POS for bars & nightclubs.",
            key: "restaurants-bars-and-nightclubs",
            path: "/nightclub-and-bar-pos/",
          },
          {
            label: "Quick Service",
            description: "Restaurant tech that moves as fast you do.",
            key: "restaurants-qsr",
            path: "/quick-service-pos/",
          },
          {
            label: "Casual Dining",
            description: "Integrated POS tools to turn tables faster.",
            key: "restaurants-casual-dining",
            path: "/casual-dining-pos/",
          },
          {
            label: "Food Trucks",
            description: "Portable order & payment tech for mobile kitchens.",
            key: "restaurants-food-truck",
            path: "/food-truck-pos/",
          },
          {
            label: "Advisory Council",
            description: "Built for restaurateurs, by restaurateurs.",
            key: "restaurants-advisory-council",
            path: "/company/advisory-board/",
          },
        ],
      },
      {
        label: "Retail",
        description: "Sell in-store and online, effortlessly.",
        key: "retail",
        subItems: [
          {
            label: "Overview",
            description: "Reach your customers wherever they are.",
            key: "retail-overview",
            path: "/retail/",
          },
          {
            label: "Clothing & Apparel",
            description:
              "Sell in style with modern payment tech and retail software.",
            key: "retail-clothing-and-apparel",
            path: "/retail/clothing-and-apparel/",
          },
          {
            label: "Sporting Goods",
            description: "Compete & win with better tech for selling.",
            key: "retail-sporting-goods",
            path: "/retail/sporting-goods/",
          },
          {
            label: "Home Decor",
            description:
              "Accept payments, reach more clients, and grow your business.",
            key: "retail-home-decor",
            path: "/retail/home-decor/",
          },
        ],
      },
      {
        label: "Enterprise",
        description:
          "World-class software and payments technology built to scale with your enterprise.",
        key: "bt-enterprise",
        subItems: [
          {
            label: "Overview",
            description:
              "Create an unforgettable customer experience and sell better across all lines of your business.",
            key: "bt-enterprise-overview",
            path: "/enterprise/",
          },
          {
            label: "Sports and Entertainment",
            description:
              "Shorten lines, maximize labor, and increase sales with cloud POS.",
            key: "bt-enterprise-sports",
            path: "/enterprise/sports-and-entertainment/",
          },
          {
            label: "Education Campuses ",
            description:
              "Streamline operations and create better campus experiences with integrated tech.",
            key: "bt-enterprise-campuses",
            path: "/enterprise/education-campuses/",
          },
          {
            label: "Professional Facilities ",
            description:
              "Consolidate sales with a centralized cloud POS platform for convention and event spaces.",
            key: "bt-enterprise-facilities",
            path: "/enterprise/professional-facilities/",
          },
          {
            label: "Amusements and Attractions",
            description:
              "Boost revenue and reduce lines at every guest touchpoint with omnichannel POS.",
            key: "bt-enterprise-attractions",
            path: "/enterprise/amusements-and-attractions/",
          },
          {
            label: "Enterprise Food Services",
            description:
              "Scale sales across all your client location with fast, flexible POS solutions.",
            key: "bt-enterprise-food-services",
            path: "/enterprise/enterprise-food-services/",
          },
          {
            label: "Enterprise Retail",
            description:
              "Increase sales and efficiency with omnichannel commerce solutions.",
            key: "bt-enterprise-retail",
            path: "/enterprise/enterprise-retail/",
          },
        ],
      },
      {
        label: "Automotive",
        description:
          "Better tech to connect with customers and increase sales.",
        key: "automotive",
        subItems: [
          {
            label: "Overview",
            description:
              "Fire on all cylinders with tech to run and grow your business.",
            key: "automotive-overview",
            path: "/automotive/",
          },
          {
            label: "Auto Parts",
            description: "Attract more customers and sell better.",
            key: "automotive-auto-parts",
            path: "/automotive/auto-parts/",
          },
          {
            label: "Auto Repair",
            description: "Simple tech to increase sales and get paid.",
            key: "automotive-auto-repair",
            path: "/automotive/auto-repair/",
          },
          {
            label: "Auto Sales",
            description: "Integrated tech to connect with more customers.",
            key: "automotive-auto-sales",
            path: "/automotive/auto-sales/",
          },
          {
            label: "Oil Change Shop",
            description: "Frictionless tech to grow your bottom line.",
            key: "automotive-oil-change-shop",
            path: "/automotive/oil-change-shop/",
          },
          {
            label: "Tire Center",
            description: "Integrated tools to boost sales.",
            key: "automotive-tire-shop",
            path: "/automotive/tire-center/",
          },
        ],
      },
      {
        label: "Professional Services",
        description:
          "One platform for appointments, invoicing, marketing, and more.",
        key: "professional-services",
        subItems: [
          {
            label: "Overview",
            description: "Connect with clients. Grow your business.",
            key: "professional-services-overview",
            path: "/professional-services/",
          },
          {
            label: "Accountants",
            description:
              "Software to grow your client base and take payment seamlessly.",
            key: "professional-services-accountant",
            path: "/professional-services/accountants/",
          },
          {
            label: "Plumbers",
            description: "Take payments easily. On the road. In the office.",
            key: "professional-services-plumber",
            path: "/professional-services/plumbers/",
          },
          {
            label: "Veterinarians",
            description:
              "One platform for appointments, payments, and marketing.",
            key: "health-and-beauty-veterinarian",
            path: "/professional-services/veterinarian/",
          },
        ],
      },
      {
        label: "Health & Beauty",
        description:
          "All-in-one platform for appointments, scheduling, and payments.",
        key: "health-and-beauty",
        subItems: [
          {
            label: "Overview",
            description:
              "Book more appointments, reach more clients, and increase sales.",
            key: "health-and-beauty-overview",
            path: "/health-and-beauty/",
          },
          {
            label: "Hair Salon",
            description: "Software & payment solutions for hair salons.",
            key: "health-and-beauty-hair-salon",
            path: "/health-and-beauty/hair-salons/",
          },
          {
            label: "Nail Salon",
            description: "More appointments. Fewer no-shows.",
            key: "health-and-beauty-nail-salon",
            path: "/health-and-beauty/nail-salons/",
          },
          {
            label: "Barber",
            description: "Tech for keeping your chairs full.",
            key: "health-and-beauty-barber",
            path: "/health-and-beauty/barber/",
          },
          {
            label: "Tanning Salon",
            description: "Save time & money. Increase sales.",
            key: "health-and-beauty-tanning-salon",
            path: "/health-and-beauty/tanning-salon/",
          },
          {
            label: "Dentists",
            description:
              "Integrated tech for appointments, marketing, and payments.",
            key: "health-and-beauty-dentist",
            path: "/health-and-beauty/dentist/",
          },
        ],
      },
      // {
      //   label: "Retail",
      //   description: "Sell in-store and online, effortlessly.",
      //   key: "retail",
      //   subItems: [
      //     {
      //       label: "Overview",
      //       description: "Reach your customers wherever they are.",
      //       key: "retail-overview",
      //       path: "/products/terminal/",
      //     },
      //     {
      //       label: 'Clothing & Apparel',
      //       description:
      //         'Sell in style with modern payment tech and retail software.',
      //       key: 'retail-clothing-and-apparel',
      //       path: '/retail/clothing-and-apparel',
      //     },
      //     {
      //       label: 'Sporting Goods',
      //       description: 'Compete & win with better tech for selling.',
      //       key: 'retail-sporting-goods',
      //       path: '/retail/sporting-goods',
      //     },
      //     {
      //       label: 'Home Decor',
      //       description:
      //         'Accept payments, reach more clients, and grow your business.',
      //       key: 'retail-home-decor',
      //       path: '/retail/home-decor',
      //     },
      //   ],
      // },
    ],
  },
  {
    label: "Why SpotOn?",
    key: "company",
    items: [
      {
        label: "Why SpotOn",
        description: "You deserve a partner who shares your values.",
        key: "sales",
        path: "/company/why-spoton/",
      },
      {
        label: "About Us",
        description: "Helping businesses at the core of our communities.",
        key: "reward",
        path: "/company/about-us/",
      },
      {
        label: "Careers",
        description: "We never stop looking for talented people.",
        key: "team",
        path: "/careers/",
      },
      {
        label: "Media",
        description: "Press releases and media resource-center.",
        key: "knowledge",
        path: "/media/",
      },
      {
        label: "Restaurant Advisory Council",
        description: "Built for restaurateurs, by restaurateurs.",
        key: "why",
        path: "/company/advisory-board/",
      },
      {
        label: "",
        description: "",
        key: "placeholder",
        path: "/",
      },
    ],
  },
  // {
  //   label: "Blog",
  //   key: "blog",
  //   path: "https://spoton.com/blog/",
  //   externalURL: true,
  // },
  {
    label: "Resources",
    key: "resources",
    items: [
      {
        label: "Overview",
        description: "A birds-eye-view of the SpotOn resource center.",
        key: "r-overview",
        path: "/resource-center/",
      },
      {
        label: "White papers",
        description:
          "An in-depth look at how technology can help your business.",
        key: "r-white-papers",
        path: `/resource-center/${resourceCategories.WHITE_PAPERS}`,
      },
      {
        label: "Videos",
        description:
          "Testimonials on how businesses are using SpotOn technology.",
        key: "r-videos",
        path: `/resource-center/${resourceCategories.VIDEOS}`,
      },
      {
        label: "Case studies",
        description: "The stories and successes of SpotOn customers.",
        key: "r-case-studies",
        path: `/resource-center/${resourceCategories.CASE_STUDIES}`,
      },
      {
        label: "Infographics",
        description:
          "Quick visuals to understand the information behind the data.",
        key: "r-infographics",
        path: `/resource-center/${resourceCategories.INFOGRAPHICS}`,
      },
      {
        label: "Blog",
        description: "The latest news and insights to help your business win.",
        key: "r-blog",
        path: "https://spoton.com/blog/",
      },
      {
        label: "Tools",
        description:
          "Calculators, templates, and other resources to help grow your business.",
        key: "r-tools",
        path: `/resource-center/${resourceCategories.TOOLS}`,
      },
      {
        label: "Developer center",
        description:
          "The tools and support you need to successfully integrate with SpotOn",
        key: "r-developer-center",
        path: `/${resourceCategories.DEVELOPER_CENTER}`,
      },
      {
        label: " ",
        description: " ",
        key: "r-placeholder-1",
        path: "/",
        className: "invisible",
      },
      {
        label: " ",
        description: " ",
        key: "r-placeholder-2",
        path: "/",
        className: "invisible",
      },
    ],
  },
];

export default navData;
