import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Footer } from "antd/lib/layout/layout";
import Image from "../../ImageQuerys/FooterImages";
import './FooterStyles.js';

import PrimaryCta from "../../Buttons/primary-cta";
import LinkList from "./LinkList";
import HelpCenterComponent from "./HelpCenter";

import Logo from "../../../images/svg/SpotOnLogo.svg";
import LanguageIcon from "../../../images/svg/language-icon.svg";
import Facebook from "../../../images/svg/fb.svg";
import Instagram from "../../../images/svg/instagram.svg";
import Twitter from "../../../images/svg/twitter.svg";
import Youtube from "../../../images/svg/youtube.svg";
import Linkedin from "../../../images/svg/linkedin.svg";
import iconOverview from "../../../images/svg/icons/delivery-logistics.svg";
import iconEcomm from "../../../images/svg/icons/e-commerce.svg";
import iconEA from "../../../images/svg/icons/eA_Icon.svg";
import { getDemoUrl } from "../../../utils/url-utils";
import useShouldHideDemoCta from "../../../hooks/use-should-hide-demo-cta";

const { Title, Paragraph } = Typography;
const { SubMenu } = Menu;



const FooterComponent = ({ sectionData, phoneNumber, phoneText }) => {
    const {
        title,
        primaryCta,
        helpCenter,
        products,
        restaurant,
        services,
        retail,
        company,
        bottom,
    } = sectionData;
    const [pagePath, setPagePath] = useState("");
    const hideDemoCta = useShouldHideDemoCta();
    const forcedRole = { role: "list" };
    let checkPath;
    if (typeof window !== `undefined`) {
        checkPath = window.location.pathname;
    }

    const menuId = hideDemoCta ? 'footer-menu-without-border-right' : 'footer-menu-with-border-right';

    useEffect(() => {
        setPagePath(checkPath);
    });

    return (       
        <Footer role="contentinfo" className="footer" lg={24}>
            <Col>
                {pagePath !== "careers" && (
                    <Row className="footer__top">
                        <Col xl={12} md={12} sm={24} xs={24} className="footer__top__title">
                            <Title level={2}>
                                {title}
                                <span className="blue_dot">.</span>
                            </Title>
                        </Col>
                        <Col xl={12} md={12} sm={24} xs={24} className="footer__top__links">
                            <div className="login-item">
                                <Menu id={menuId} role="menu">
                                    <SubMenu
                                        key="login"
                                        title="Log In"
                                        role="list"
                                        popupClassName="login-item__pop-up max-w-40"
                                        popupOffset={[-100, 60]}
                                    >
                                        <Menu.Item key="merchant" role="listitem">
                                            <Row align="middle" justify="start">
                                                <div xs={6} className="icon-wrapper">
                                                    <img src={iconEcomm} alt="icon" />
                                                </div>
                                                <Col xs={16}>
                                                    <Link
                                                        href="https://login.spoton.com/home/"
                                                        activeClassName="active"
                                                        partiallyActive
                                                    >
                                                        <p className="menu-item-header">Merchant</p>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Menu.Item>
                                        <Menu.Item key="partner" role="listitem">
                                            <Link
                                                href="https://thespot.spoton.com/"
                                                activeClassName="active"
                                                partiallyActive
                                            >
                                                <Row align="middle" justify="start">
                                                    <div xs={6} className="icon-wrapper">
                                                        <img src={iconOverview} alt="icon coffee" />
                                                    </div>
                                                    <Col xs={16}>
                                                        <p className="menu-item-header">Partner</p>
                                                    </Col>
                                                </Row>
                                            </Link>
                                        </Menu.Item>
                                    </SubMenu>
                                </Menu>
                            </div>
                            {!hideDemoCta && (
                                <PrimaryCta
                                    className="get-your-demo"
                                    ctaTitle="Learn more"
                                    target={
                                        // eslint-disable-next-line no-nested-ternary
                                        pagePath === "/products/reserve"
                                            ? `/products-demo`
                                            : typeof window !== "undefined"
                                                ? getDemoUrl(window.location.pathname)
                                                : "/demo"
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                )}
                {/* <Image className="sep top-sep" imageName="sep.png" /> */}
                <Row>
                    <Col className="social-section" xl={10} md={12}>
                        <img src={Logo} alt="logo" />
                        <Paragraph className="social-section__text">
                            {`© SpotOn Transact, LLC. ${new Date().getFullYear()}. All Rights Reserved.`}
                            <span style={{ fontSize: 12 }}>
                                {" "}
                                <br />
                                SpotOn Transact, LLC. is a Registered Partner/ISO of<br />
                                Merrick Bank, South Jordan, UT &amp; Wells Fargo Bank,<br /> 
                                N.A., Concord, CA &amp; Elavon Inc., Georgia [a wholly<br /> 
                                owned subsidiary of U.S. Bancorp, Minneapolis, MN]. 
                            </span>
                        </Paragraph>
                        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                        <ul className="social-section__links" role="list">
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <li role="listitem">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/spoton"
                                >
                                    <img src={Facebook} alt="facebook-social-icon" />
                                </a>
                            </li>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <li role="listitem">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/spoton/"
                                >
                                    <img src={Instagram} alt="instagram-social-icon" />
                                </a>
                            </li>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <li role="listitem">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://twitter.com/spoton"
                                >
                                    <img src={Twitter} alt="instagram-social-icon" />
                                </a>
                            </li>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <li role="listitem">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="http://youtube.com/c/spotoninc"
                                >
                                    <img src={Youtube} alt="youtube-social-icon" />
                                </a>
                            </li>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <li role="listitem">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.linkedin.com/company/spoton/mycompany/"
                                >
                                    <img src={Linkedin} alt="linkedin-social-icon" />
                                </a>
                            </li>
                        </ul>
                        <a
                            target="blank"
                            rel="noopener"
                            href="https://www.essentialaccessibility.com/spoton?utm_source=spotonhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=spoton"
                        >
                            <img
                                style={{ width: 125, marginTop: 15, magrin: "auto" }}
                                src={iconEA}
                                alt="This icon serves as a link to download the eSSENTIAL Accessibility assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion."
                            />
                        </a>
                    </Col>
                    <HelpCenterComponent
                        md={12}
                        helpCenter={helpCenter}
                        className="footer__help-center--mobile"
                        phoneText={phoneText}
                        phoneNumber={phoneNumber}
                    />
                    <Col xs={24} xl={{ span: 13, offset: 1 }}>
                        <LinkList
                            className="products-list"
                            title={products.title}
                            links={products.items}
                        />
                        <Image className="sep" imageName="sep.png" />
                    </Col>
                </Row>

                <Row className="footer__help-center-wrapper">
                    <HelpCenterComponent
                        helpCenter={helpCenter}
                        className="footer__help-center--desktop"
                        phoneText={phoneText}
                        phoneNumber={phoneNumber}
                    />

                    <Col xs={24} xl={{ span: 13, offset: 1 }}>
                        <Row className="links-container">
                            <Col xs={12} md={8}>
                                <LinkList
                                    className="restaurant-list"
                                    title={restaurant.title}
                                    links={restaurant.items}
                                />
                            </Col>
                            <Col xs={12} md={8}>
                                <LinkList title={services.title} links={services.items} />
                            </Col>
                            <Col xs={12} md={8}>
                                <LinkList
                                    className="no-border-bottom"
                                    title={retail.title}
                                    links={retail.items}
                                />
                            </Col>
                            <Image className="sep" imageName="sep.png" />
                            <Col xs={12} md={24} xl={24}>
                                <LinkList
                                    className="company-list"
                                    title={company.title}
                                    links={company.items}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="footer__bottom">
                    <Image className="footer__bottom__sep" imageName="sep.png" />
                    <Col xl={16}>
                        <Menu role="list">
                            {bottom.links.map((link) => (
                                <Menu.Item role="listitem" key={link.title}>
                                    <Link to={link.target || ""}>{link.title}</Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Col>
                    <Col xl={8} className="footer__bottom__language">
                        <Typography>English</Typography>{" "}
                        <img src={LanguageIcon} alt="language-icon" />
                    </Col>
                </Row>
            </Col>
        </Footer>
    );
};

FooterComponent.propTypes = {
    sectionData: PropTypes.objectOf(PropTypes.any),
    phoneNumber: PropTypes.string,
    phoneText: PropTypes.string,
};
FooterComponent.defaultProps = {
    sectionData: {},
    phoneNumber: null,
    phoneText: null,
};

export default FooterComponent;
