import React, { useContext } from "react";
import { string, array, bool } from "prop-types";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import { isMobile } from "react-device-detect";
import { colors, viewports } from "../../style-vars";
import ArrowIcon from "../../images/svg/nav-icons/arrow.inline.svg";
import HeaderContext from "./header-context";
import AppContext from "../../components/app-context";
import icons from "./icons-map";

const variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.2 },
  },
};

const MainNavItem = ({
  className,
  label,
  description,
  path,
  keyProp,
  subItems,
  animate,
}) => {
  const {
    activeKey,
    setActiveKey,
    activeMainNavItemKey,
    setActiveMainNavItemKey,
    setMainNavItems,
    externalURL,
    setMainNavSubItems,
    setSubItemsTitle,
    hasMobileNav,
  } = useContext(HeaderContext);

  const {
    toggleNavVisible,
    setSubItemsVisible,
    toggleSubItemsVisible,
    setNavVisible,
  } = useContext(AppContext);

  const handleClick = () => {
    if (externalURL && typeof window !== "undefined") {
      setActiveMainNavItemKey("");
      window.location = "https://spoton.com/blog/";
    }
    if (path) {
      setActiveMainNavItemKey("");
      return navigate(path);
    }

    // if (activeMainNavItemKey === keyProp) {
    //   toggleSubItemsVisible();
    //   setActiveMainNavItemKey('');
    //   // setMainNavSubItems([]);
    //   setSubItemsTitle('');
    //
    //   return true;
    // }

    setSubItemsTitle(label);
    setSubItemsVisible(true);
    setMainNavSubItems(subItems);
    return setActiveMainNavItemKey(keyProp);
  };

  const onMouseEnter = () => {
    setSubItemsTitle(label);
    setSubItemsVisible(true);
    setMainNavSubItems(subItems);
    setActiveMainNavItemKey(keyProp);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <li role="listitem" className={className}>
      <motion.button
        type="button"
        className={`main-nav-item ${
          activeMainNavItemKey === keyProp ? "active" : ""
        }`}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        variants={variants}
        initial={isMobile ? "visible" : "hidden"}
        animate={animate || isMobile ? "visible" : "hidden"}
      >
        <div className="main-nav-item__info-wrapper">
          <div className="main-nav-item__icon">
            {icons.get(keyProp)}
            <div />
          </div>
          <div className="main-nav-item__info">
            <span className="main-nav-item__info--label">{label}</span>
            <span className="main-nav-item__info--description">
              {description}
            </span>
          </div>
        </div>
        {path ? null : <ArrowIcon className="main-nav-item__arrow" />}
      </motion.button>

      <style jsx global>{`
        .main-nav-item {
          background: none;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          margin: 12px 0;
          transition: 0.2s ease-in-out;
        }

        .main-nav-item__icon svg {
          border-radius: 6px;
        }

        .main-nav-item__arrow {
          transition: 0.2s ease-in-out;
          flex: 0 0 auto;
        }

        .main-nav-item__info-wrapper {
          display: flex;
          align-items: center;
        }

        .main-nav-item__info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        }

        .main-nav-item__info--label {
          font-weight: bold;
          font-size: 16px;
          color: #090d12;
        }

        .main-nav-item__info--description {
          font-weight: normal;
          font-size: 14px;
          color: #748093;
        }

        .main-nav-item rect.background {
          fill: #e8f0ff;
        }

        @media ${viewports.lgOrBigger} {
          .main-nav-item {
            width: 450px;
          }

          .main-nav-item__info {
            margin-left: 24px;
          }

          .main-nav-item__info--label {
            //font-weight: normal;
            font-size: 18px;
          }

          // <--- Icons colors --->
          .main-nav-item:hover .main-nav-item__icon svg rect,
          .main-nav-item.active .main-nav-item__icon svg rect {
            transition: 0.2s ease-in-out;
            fill: ${colors.primary};
          }
          .main-nav-item:hover svg path,
          .main-nav-item:hover svg circle,
          .main-nav-item:hover svg rect,
          .main-nav-item:hover svg line,
          .main-nav-item.active svg path,
          .main-nav-item.active svg circle,
          .main-nav-item.active svg rect,
          .main-nav-item.active svg line {
            stroke: ${colors.white};
          }
          .main-nav-item:hover .main-nav-item__arrow rect,
          .main-nav-item.active .main-nav-item__arrow rect {
            transition: 0.2s ease-in-out;
            fill: ${colors.white};
          }
          .main-nav-item:hover .main-nav-item__arrow path,
          .main-nav-item.active .main-nav-item__arrow path {
            stroke: none;
          }
          // <--- End of Icons colors --->

          .main-nav-item:hover,
          .main-nav-item.active {
            cursor: pointer;
            background: #e8f0ff;
            border-radius: 6px;
          }
          .main-nav-item:hover .main-nav-item__info--label,
          .main-nav-item.active .main-nav-item__info--label {
            color: ${colors.primary};
          }

          .main-nav-item.active .main-nav-item__arrow {
            transform: rotate(180deg);
          }
        }
      `}</style>
    </li>
  );
};

MainNavItem.propTypes = {
  className: string,
  label: string.isRequired,
  keyProp: string.isRequired,
  path: string,
  description: string,
  subItems: array,
  animate: bool,
};

MainNavItem.defaultProps = {
  className: "",
  description: "",
  subItems: [],
  animate: false,
};

export default MainNavItem;
