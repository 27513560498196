import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout, Col } from "antd";
import { bool, string } from "prop-types";
import Logo from "../../../images/svg/SpotOnLogo.svg";
import LogoWhite from "../../../images/svg/SpotOnLogoWhite.svg";
import HeaderStyles from "./HeaderStyles";
import phone from "../../../images/svg/phone.svg";
import useWindowSize from "../../../hooks/use-window-size";
import useGetViewportY from "../../../hooks/use-get-viewport-y";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import useShouldHideDemoCta from "../../../hooks/use-should-hide-demo-cta";

const { Header } = Layout;

// eslint-disable-next-line react/prop-types
const HeaderComponent = (
  { phoneNumber, phoneText, transparentHeader, getADemo },
  noTopOffset
) => {
  const [shouldBeTransparent, setShouldBeTransparent] = useState(false);
  const hideDemoCta = useShouldHideDemoCta();
  const viewportY = useGetViewportY();
  const size = useWindowSize();
  const LgOrSmaller = size.width < 1200;

  useEffect(() => {
    setShouldBeTransparent(Math.abs(viewportY) <= 100 && transparentHeader);
  }, [viewportY]);

  return (
    <Header
      role="banner"
      className={`nav header is-lp ${shouldBeTransparent ? "transparent" : ""}`}
      style={{
        /* eslint-disable-next-line no-nested-ternary */
        top: noTopOffset ? 0 : LgOrSmaller ? 90 : 60,
      }}
    >
      <Col
        xs={{ span: 13 }}
        sm={{ span: 12 }}
        md={{ span: 10 }}
        lg={{ span: 4, offset: 1 }}
        xl={{ span: 4, offset: 1 }}
        xxl={{ span: 4, offset: 1 }}
      >
        <Link to="/" style={{ margin: "auto" }}>
          <img
            className="header__logo"
            src={shouldBeTransparent ? LogoWhite : Logo}
            alt="logo"
          />
        </Link>
      </Col>

      {!getADemo && (
        <Col xs={10} sm={8} md={7} lg={6} className="phone">
          <a href={`tel:${phoneNumber}`}>
            <img src={phone} alt="phone icon" />
            <span>{phoneText}</span>
          </a>
        </Col>
      )}
      {getADemo && !hideDemoCta && (
        <Col xs={10} sm={8} md={7} lg={6}>
          <CtaPrimary title="Get a demo" target="/gated-assets/demo" />
        </Col>
      )}

      <HeaderStyles />
    </Header>
  );
};

HeaderComponent.propTypes = {
  transparentHeader: bool,
  phoneNumber: string,
  phoneText: string,
  getADemo: bool,
};

HeaderComponent.defaultProps = {
  transparentHeader: false,
  phoneNumber: "",
  phoneText: "",
  getADemo: false,
};

export default HeaderComponent;
