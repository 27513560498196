import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { Row } from "antd";
import CustomBannerStyles from "./CustomBannerStyles";
import CtaSecondary from "../Buttons/secondary-cta";
import Image from "../ImageQuerys/LargeFeaturesImages";

const item = {
  hidden: {
    opacity: 0,
    y: -200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  },
};
const circleA = {
  hidden: {
    opacity: 0,
    y: -200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1,
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 2,
    },
  },
};

const circleB = {
  hidden: {
    opacity: 0,
    y: 200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1,
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 2,
    },
  },
};

const BANNER_CLASSNAME = "with-banner";

const CustomBanner = (props) => {
  const { hideBanner, customData } = props;

  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];

    return hideBanner
      ? bodyElement.classList.remove(BANNER_CLASSNAME)
      : bodyElement.classList.add(BANNER_CLASSNAME);
  }, [hideBanner]);

  return (
    <>
      <motion.div
        className="custom-banner"
        variants={item}
        initial="hidden"
        animate="show"
        role="marquee"
        style={{
          display: hideBanner ? "none" : "block",
        }}
      >
        <motion.div
          variants={circleA}
          initial="hidden"
          animate="show"
          className="banner-image-left"
        >
          <Image imageName="HalfFill.png" />
        </motion.div>
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          <span className="text-center banner-bold" style={{ marginRight: 10 }}>
            {customData.headline}
            <span>{customData.punctuation}</span>
          </span>
          <div className="mobile-center">
            <CtaSecondary
              ctaTitle={customData.btnText}
              target={customData.btnTarget}
              style={{ color: "#1769FF" }}
              className="banner-cta"
            />
          </div>
          {/* <CtaSecondary
          ctaTitle={customData.btnText}
          target={customData.btnTarget}
          style={{ color: '#4089ff', marginBottom: 10 }}
          className="banner-cta"
        /> */}
        </Row>
        <motion.div
          className="banner-image-right"
          variants={circleB}
          initial="hidden"
          animate="show"
        >
          <Image imageName="CircleOutline.png" />
        </motion.div>
      </motion.div>
      <CustomBannerStyles />
    </>
  );
};

CustomBanner.propTypes = {
  hideBanner: PropTypes.bool,
  customData: PropTypes.objectOf(PropTypes.any),
};
CustomBanner.defaultProps = {
  hideBanner: false,
  customData: {},
};

export default CustomBanner;
