import React from "react";
import { viewports, colors, fontFamily } from "../../../style-vars";

const { white, black, primary, black200 } = colors;
const {
  lgOnly,
  mdOrSmaller,
  xlOrBigger,
  lgOrBigger,
  smOrSmaller,
  mdOnly,
  xsOnly,
  smOnly,
} = viewports;

const HeaderStyles = () => (
  <style jsx global>{`
    .nav {
      position: fixed;
      //background: ${white} !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 72px;
      padding: 0;
      z-index: 9;
      max-width: 1440px;
      transition: all 0.5s ease-in-out;
    }
    .sec-menu {
      list-style-type: none;
      list-style: none;
      margin: 0;
      padding: 24px 8px;
    }
    .sec-menu li {
      margin: 24px 0;
    }
    .sec-menu a {
      color: rgb(36, 52, 81);
      font-weight: 700;
      font-size: 20px;
    }
    .specialty-menu {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .specialty-menu li {
      white-space: pre-line;
      line-height: 1.2;
    }
    .specialty-menu a {
      color: rgb(36, 52, 81);
      padding: 0px 15px 0 0;
      font-weight: 700;
      font-family: sofia-pro, sans-serif;
      font-size: 16px;
      text-decoration: none !important;
    }
    .nav.is-lp {
      margin: auto;
      /* width: 100%; */
      position: sticky;
      display: flex;
      justify-content: space-between;
    }

    .nav.is-lp .phone {
      text-align: right;
      margin-right: 20px;
    }
    .nav.is-lp .phone a {
      padding: 8px 16px;
      background: #e4e6e9;
      border-radius: 30px;
      font-weight: normal;
      font-size: 18px;
      padding: 15px;
    }

    .nav.is-lp .phone a span {
      margin-left: 8px;
      color: ${primary} !important;
      font-weight: 600;
    }
    @media ${viewports.smOrSmaller} {
      .nav.is-lp .phone a span {
        display: none;
      }
    }

    .nav.full-size {
      max-width: 100vw;
    }

    .nav a:active {
      color: ${black} !important;
    }

    .nav .header__desktop-menu {
      width: 100%;
      text-align: center;
    }

    .nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .nav .header__logo {
      margin-bottom: 5px !important;
      width: 55% !important;
      max-width: 180px;
    }

    .nav .ant-menu {
      background: transparent !important;
      font-family: ${fontFamily};
      font-weight: normal;
      font-size: 16px !important;
      line-height: 4.25;
    }

    ul.ant-menu {
      border-right: none !important;
    }

    .nav .mobile-burger-menu {
      margin-right: 20px !important;
      margin-bottom: 10px !important;
    }

    .nav .header__contact {
      text-align: center;
    }

    .nav .header__contact a {
      position: relative;
      color: ${white} !important;
      font-family: ${fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
    }

    .nav .header__contact .header__contact--line {
      z-index: -1;
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      border-bottom: 3px solid ${primary};
      top: 17px;
    }

    .nav.scrolled {
      background: ${white} !important;
      box-shadow: 0 0 5px 2px rgba(189, 189, 189, 1);
    }

    .nav.scrolled .ant-menu-submenu-title span {
      color: #1b1c1d !important;
    }

    .nav.scrolled .ant-menu-item a {
      color: #1b1c1d !important;
    }
    .nav.scrolled .ant-menu-item a:active,
    .nav.scrolled .ant-menu-item a:hover {
      color: ${primary} !important;
    }

    .nav.scrolled .header__contact a {
      color: #1b1c1d !important;
    }

    .ant-menu-submenu-vertical:hover,
    .ant-menu-submenu-vertical:focus {
      background-color: #e8f0ff;
    }

    .login-item {
      width: 100px !important;
    }

    .login-item__mobile {
      width: 100% !important;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .login-item__mobile .ant-menu-submenu-title {
      color: ${primary} !important;
    }

    .login-item__mobile .ant-menu-submenu-arrow {
      color: ${primary} !important;
    }

    .login-item .ant-menu-submenu-title {
      color: ${primary} !important;
    }

    .login-item .ant-menu-submenu-arrow {
      color: ${primary} !important;
      transform: rotate(90deg);
      margin-top: 3px;
    }

    .login-item__pop-up {
      position: fixed;
    }
    .sub-nav-with-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .with-subheader {
      line-height: 1.45 !important;
      height: 100% !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .with-subheader:hover,
    .with-subheader:focus {
      background-color: #e8f0ff;
    }

    .pop-up {
      position: fixed;
    }
    .parent-pop-up {
      position: fixed;
      top: 140px !important;
    }
    .ant-menu-submenu-title .anticon + span {
      white-space: pre-wrap;
      line-height: 1.45 !important;
    }
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      margin-left: 0 !important;
    }
    .ant-menu-submenu .ant-menu-submenu-vertical .ant-menu-submenu-title {
      height: 100% !important;
      min-height: 60px !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      align-items: center !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-submenu-horizontal)
      .ant-menu:not(.ant-menu-inline)
      .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      background-color: #e8f0ff;
    }

    .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title:hover {
      background-color: ${white} !important;
    }

    .ant-menu-horizontal .ant-menu-submenu-active {
      background-color: ${white} !important;
    }

    // .ant-menu-horizontal .ant-menu-item.ant-menu-item-only-child {
    //   background-color: ${white} !important;
    // }

    .menu-item-header {
      margin: 0 !important;
    }
    .menu-item-subheader {
      color: ${black200};
      margin: 0 !important;
    }

    .phone {
      font-size: 20px;
      font-weight: 600;
      margin-top: 0 !important;
    }

    .ant-drawer .ant-menu-item,
    .ant-drawer .ant-menu-submenu {
      font-size: 16px !important;
    }

    .ant-drawer .ant-drawer-content-wrapper {
      box-shadow: none !important;
    }

    @media ${lgOnly} {
      .nav .header__desktop-menu {
        text-align: right;
      }

      .nav .ant-menu .ant-menu-item {
        padding: 0 10px;
      }
    }

    @media ${xlOrBigger} {
      .nav .header__contact {
        text-align: right;
      }
    }

    @media ${mdOrSmaller} {
      .nav {
        min-height: 75px;
      }
      .table-hidden {
        display: none;
      }
      .nav .header__logo {
        width: 150px !important;
        margin-left: 20px;
      }

      .phone {
        margin-top: 18px;
        font-size: 18px;
      }
    }

    @media ${lgOrBigger} {
      .nav.scrolled .header__logo {
        width: 120px;
        height: auto;
        padding-top: 0;
      }

      .ant-drawer {
        display: none;
      }
    }

    @media ${smOrSmaller} {
      .ant-drawer {
        margin-top: 71px;
      }
      .nav {
        margin-top: 10px;
      }
    }

    @media ${mdOnly} {
      header .ant-layout-header {
        top: 95px !important;
      }
      .nav {
        margin-top: 10px;
      }
      .ant-drawer {
        margin-top: 72px;
      }

      .ant-drawer .ant-drawer-content-wrapper {
        width: 50% !important;
      }
    }

    @media ${xsOnly} {
      .ant-drawer .ant-drawer-content-wrapper {
        width: 100% !important;
      }
    }

    @media ${smOnly} {
      .ant-drawer .ant-drawer-content-wrapper {
        width: 70% !important;
      }
    }

    header.ant-layout-header {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      padding: 0;
      height: 72px;
      z-index: 10;
      display: flex;
      justify-content: center;
      background: ${colors.white};
      transition: 0.5s;

      box-shadow: 0 15px 80px rgba(24, 36, 65, 0.07),
        0 6.26664px 33.4221px rgba(24, 36, 65, 0.0503198),
        0 3.35045px 17.869px rgba(24, 36, 65, 0.0417275),
        0 1.87823px 10.0172px rgba(24, 36, 65, 0.035),
        0 0.997515px 5.32008px rgba(24, 36, 65, 0.0282725),
        0 0.415088px 2.21381px rgba(24, 36, 65, 0.0196802);
    }
    header.ant-layout-header.transparent {
      background: transparent;
      transition: 0.5s;
      box-shadow: none;
    }

    header.ant-layout-header.transparent .login-item .ant-menu-title-content {
      color: ${colors.white};
    }
    header.ant-layout-header.transparent
      .login-item
      .ant-menu-submenu-arrow:before,
    header.ant-layout-header.transparent
      .login-item
      .ant-menu-submenu-arrow:after {
      background: ${colors.white};
    }

    .nav.transparent,
    .nav.transparent .ant-menu-submenu-popup > .ant-menu,
    .nav.transparent .ant-menu-submenu,
    .nav.transparent .ant-menu-submenu ul.ant-menu-sub,
    .nav.transparent .ant-menu-submenu-popup .ant-menu,
    .nav.transparent .ant-menu-submenu-vertical:hover,
    .nav.transparent .ant-menu-submenu-vertical:focus {
      background: transparent !important;
      box-shadow: none;
    }
    .nav.transparent ul li.ant-menu-item-only-child,
    .nav.transparent ul li.ant-menu-submenu-open,
    .nav.transparent ul li.ant-menu-submenu-active,
    .nav.transparent ul li div.ant-menu-submenu-title:hover,
    .nav.transparent ul li div.ant-menu-submenu-title:active {
      background: transparent !important;
      transition: 0.5s;
    }
    .nav li.ant-menu-item {
      background: transparent !important;
    }
    .nav.transparent li.ant-menu-item,
    .nav.transparent li.ant-menu-item a,
    .nav.transparent li.ant-menu-submenu {
      color: white !important;
    }
  `}</style>
);

export default HeaderStyles;
