import React from "react";
import { string, object, bool, oneOf, oneOfType } from "prop-types";

const getHeading = (level, styleInjection, className, text, styles) => {
  if (level === 1) {
    return (
      <h1 className={`${styleInjection} ${className}`} style={{ ...styles }}>
        {text}
      </h1>
    );
  }

  if (level === 2) {
    return <h2 className={`${styleInjection} ${className}`}>{text}</h2>;
  }

  if (level === 3) {
    return <h3 className={`${styleInjection} ${className}`}>{text}</h3>;
  }

  if (level === 4) {
    return <h4 className={`${styleInjection} ${className}`}>{text}</h4>;
  }

  return null;
};

const Heading = ({
  className,
  level,
  injectionType,
  withBlueSymbol,
  symbol,
  noMargin,
  children,
  styles,
}) => {
  let styleInjection;
  switch (injectionType) {
    case 1:
      styleInjection = `heading font-sans font-bold ${
        noMargin ? "" : "mb-6 lg:mb-8"
      } text-h1-small lg:text-h1`;
      break;
    case 2:
      styleInjection = `heading font-sans font-bold ${
        noMargin ? "" : "mb-6 lg:mb-8"
      } text-h2-small lg:text-h2`;
      break;
    case 3:
      styleInjection = `heading font-sans font-bold ${
        noMargin ? "" : "mb-6 lg:mb-8"
      } text-h3-small lg:text-h3`;
      break;
    case 4:
      styleInjection = `heading font-sans font-bold ${
        noMargin ? "" : "mb-6 lg:mb-8"
      } text-h4-small lg:text-h4`;
      break;
    case 5:
      styleInjection = `heading font-sans font-bold ${
        noMargin ? "" : "mb-6 lg:mb-8"
      } text-h4-small`;
      break;
    default:
      styleInjection = `heading font-sans font-bold ${
        noMargin ? "" : "mb-6 lg:mb-8"
      }`;
      break;
  }

  const EndSymbol = !withBlueSymbol ? (
    <></>
  ) : (
    <span className="text-primary">{symbol}</span>
  );

  const Text = (
    <>
      {children}
      {EndSymbol}
    </>
  );

  return getHeading(level, styleInjection, className, Text, styles);
};

Heading.propTypes = {
  className: string,
  children: oneOfType([string, object]),
  level: oneOf([1, 2, 3, 4]),
  injectionType: oneOf([1, 2, 3, 4]),
  withBlueSymbol: bool,
  symbol: string,
  noMargin: bool,
};

Heading.defaultProps = {
  className: "",
  children: "",
  level: 1,
  injectionType: 1,
  withBlueSymbol: false,
  symbol: ".",
  noMargin: false,
};

export default Heading;
